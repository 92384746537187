import React from "react";
import "./Modal.scss";

const Modal = (props) => {
	if (!props.show) {
		return null;
	}

	return (
		<div className="customModalBackdrop" onClick={props.onClose}>
			<div
				className="customModal slideInUp"
				onClick={(e) => e.stopPropagation()}
			>
				{props.children}
			</div>
		</div>
	);
};

export default Modal;
