import React from "react";
import { Redirect } from "react-router-dom";
import commonStore from "./commonStore";
import { useUsers } from "../auth/UserContext";

const adminProtectedPage = (WrappedCompontent) => {
	const AdminProtectedComponent = (props) => {
		const userContext = useUsers();

		const user = userContext.user;

		const isAdmin = user && user.isAdmin;
		const authenticated = commonStore.getToken();

		return (
			<div>
				{authenticated && isAdmin ? (
					<WrappedCompontent {...props} />
				) : !authenticated || !isAdmin ? (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location },
						}}
					/>
				) : null}
			</div>
		);
	};
	return AdminProtectedComponent;
};

export default adminProtectedPage;
