import React, { useState } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import CategoryStore from "../category/CategoryStore";

const CategoryDropdown = (props) => {
	const [categories, setCategories] = useState();
	const categoryStore = new CategoryStore();

	if (!categories) {
		categoryStore.findAll().then((data) => {
			setCategories(data);
		});
	}

	const handleChange = (e) => {
		e.persist();
		props.action(e.target.value);
	};

	let options = [];
	if (categories) {
		options = categories.map((category, idx) => {
			return (
				<option key={idx} value={category.id}>
					{category.name}
				</option>
			);
		});
	}

	return (
		<FormGroup className="selectWrapper">
			<FormControl
				as="select"
				placeholder={props.placeholder || "Categories..."}
				onChange={handleChange}
			>
				<option value="">All Categories</option>
				{options}
			</FormControl>
		</FormGroup>
	);
};

export default CategoryDropdown;
