import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IglooLogo from "../ui/IglooLogo.js";
import "./Footer.scss";

const Footer = () => {
	return (
		<footer className="igloo-footer mt-5">
			<div className="container">
				<Row className="footer-intro-section">
					<Col md={6} sm={12} className="footer-intro-text">
						<h5>Build Your Own eLearning Games</h5>
						<p>
							Start creating rich interactive content for your
							eLearning. It's free and easy to get started.
						</p>
					</Col>
					<Col
						md={6}
						sm={12}
						className="vertical-align footer-get-started"
					>
						<Link
							to="/register"
							className="btn btn-rounded common-gradient"
						>
							Get Started
						</Link>
					</Col>
				</Row>
				<Row className="footer-nav-section">
					<Col
						lg={3}
						md={4}
						sm={12}
						xs={12}
						className="footer-logo-icon"
					>
						<IglooLogo fill="#ffffff" width="180px" />
					</Col>
					<Col
						lg={3}
						md={3}
						sm={6}
						xs={6}
						className="footer-nav-block"
					>
						<strong>Features</strong>

						<ul>
							<li>
								<Link to="/library">
									Examples and Downloads
								</Link>
							</li>
							<li>
								<Link to="/features">All Features</Link>
							</li>
							<li>
								<Link to="/">About</Link>
							</li>
							<li>
								<Link to="/premium">Premium</Link>
							</li>
						</ul>
					</Col>
					<Col
						lg={3}
						md={3}
						sm={6}
						xs={6}
						className="footer-nav-block"
					>
						<strong>Resources</strong>
						<ul>
							<li>
								<button
									className="btn btn-link"
									onClick={(e) => {
										e.preventDefault();
										window.SnapEngage.startLink();
									}}
								>
									Live Chat
								</button>
							</li>
							<li>
								<button
									className="btn btn-link"
									onClick={(e) => {
										e.preventDefault();
										window.SnapEngage.startLink();
									}}
								>
									Contact Us
								</button>
							</li>
							<li>
								<Link to="/privacy-policy">Privacy Policy</Link>
							</li>
						</ul>
					</Col>
					<Col
						lg={3}
						md={2}
						sm={12}
						xs={12}
						className="text-right footer-social"
					>
						<a href="https://www.facebook.com/Igloogames-102088434474862/">
							<FontAwesomeIcon icon={faFacebook} />
						</a>
						<a href="https://twitter.com/igloo.games">
							<FontAwesomeIcon icon={faTwitter} />
						</a>
					</Col>
				</Row>
			</div>
		</footer>
	);
};

export default Footer;
