import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ProgressBar, Button } from "react-bootstrap";
import "./MediaDropzone.scss";
import filesize from "filesize";
import Loading from "../ui/Loading";
import { UploadIcon } from "igloo-common/src/components";
import {
	MEDIA_ACCEPTED_FORMATS_STRING,
	MEDIA_MAX_FILESIZE,
} from "../config/Settings";
import { useMedia } from "./MediaContext";
import { useUsers } from "../auth/UserContext";

const MediaItem = () => {
	const mediaContext = useMedia();
	const userContext = useUsers();

	const onDrop = (acceptedFiles) => {
		toast.info("Your files are being uploaded.");
		acceptedFiles.forEach((file) => {
			mediaContext.upload(file).then(() => {
				userContext.getUser();
			});
		});
	};

	const onDropRejected = (rejectedFiles) => {
		rejectedFiles.forEach((file) => {
			if (file.size > MEDIA_MAX_FILESIZE) {
				toast.error(
					`${
						file.name
					} could not be uploaded as its larger than the max allowed file size (${filesize(
						MEDIA_MAX_FILESIZE,
					)}).`,
				);
			} else {
				toast.error(
					`${
						file.name
					} could not be uploaded as its not part of the accepted file types (${this.acceptedFormatsReadable()}).`,
				);
			}
		});
	};

	const user = userContext.user;
	if (!user) {
		return <Loading />;
	}

	return (
		<>
			<Dropzone
				onDrop={onDrop}
				onDropRejected={onDropRejected}
				accept={MEDIA_ACCEPTED_FORMATS_STRING}
				maxSize={MEDIA_MAX_FILESIZE}
			>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<section
						className={
							isDragActive
								? "on-hover media-dropzone"
								: "media-dropzone"
						}
					>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<UploadIcon size="2x" className="mb-3" />
							<p>Drag files here to upload</p>
							<Button variant="link">or browse for files</Button>
						</div>
					</section>
				)}
			</Dropzone>
			<div className="media-storage-wrapper">
				<ProgressBar
					variant="success"
					now={user.organization.storageUsedPercent}
					label={`${Math.round(
						user.organization.storageUsedPercent,
					)}%`}
				/>
				<small>
					You are using {filesize(user.organization.storageUsed)} out
					of the {filesize(user.organization.storageAllocated)}{" "}
					available.
				</small>
			</div>
		</>
	);
};

export default MediaItem;
