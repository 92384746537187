import React, { useEffect } from "react";
import "./MediaPage.scss";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import MediaList from "./MediaList";
import MediaDropzone from "./MediaDropzone";
import { Col, Container } from "react-bootstrap";

const MediaPage = () => {
	useEffect(() => {
		document.title = "iglooo - Upload Media";
	}, []);

	return (
		<div className="page-media">
			<Header pageTitle="Upload Media">
				<Col md={5} className="d-none d-sm-inline">
					<h2>Media Library</h2>
				</Col>
				<Col md={6} />
			</Header>
			<Container>
				<MediaDropzone />
				<MediaList />
			</Container>
			<Footer />
		</div>
	);
};

export default MediaPage;
