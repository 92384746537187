import React, { useEffect } from "react";
import StatsMap from "./StatsMap";
import Loading from "../ui/Loading";

import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";

import "./Stats.scss";
import { useUsers } from "../auth/UserContext";

const OrganizationStats = () => {
	const userContext = useUsers();

	useEffect(() => {
		document.title = "iglooo - My Stats";
	}, []);

	if (!userContext.user) {
		return <Loading />;
	}

	return (
		<div id="page-stats">
			<Header pageTitle="My Stats" />
			<StatsMap orgId={userContext.user.organization.id} />
			<Footer />
		</div>
	);
};

export default OrganizationStats;
