import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import TagFilterDropdown from "../ui/TagFilterDropdown";
import "react-toastify/dist/ReactToastify.min.css";
import CategoryDropdown from "../ui/CategoryDropdown.js";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import Search from "../ui/Search.js";
import Pagination from "react-js-pagination";
import { INTERACTION_PAGE_SIZE } from "../config/Settings";

import "./PublicInteractions.scss";
import InteractionStore from "./InteractionStore";
import Loading from "../ui/Loading";

const PublicInteractions = (props) => {
	const [interactions, setInteractions] = useState();
	const [interactionCount, setInteractionCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [activePage, setActivePage] = useState(1);
	const [category, setCategory] = useState();
	const [tag, setTag] = useState();

	useEffect(() => {
		document.title = "iglooo - My Dashboard";
	}, []);

	useEffect(() => {
		const interactionStore = new InteractionStore();

		interactionStore
			.loadPublic(searchTerm, category, tag, activePage)
			.then((response) => {
				setInteractions(response.interactions);
				setInteractionCount(response.interactionCount);
			});
	}, [activePage, searchTerm, category, tag]);

	const search = (term) => {
		setSearchTerm(term);
		setActivePage(1);
	};

	const changePage = (page) => {
		setActivePage(page);
	};

	const filterCategory = (category) => {
		setCategory(category);
	};

	const filterTag = (tag) => {
		setTag(tag);
	};

	let result = <Loading />;
	if (interactions) {
		result = interactions.map((interaction, idx) => {
			let categories = null;
			if (interaction.categories.length) {
				categories = (
					<div key={idx} className="interaction-categories">
						<span className="interaction-categories-title">
							Categories:{" "}
						</span>
						<span>{_.join(interaction.categories, ", ")}</span>
					</div>
				);
			}

			return (
				<Col
					md={{ span: 3, offset: idx % 3 ? 1 : 0 }}
					key={idx}
					className="public-interaction-item"
				>
					<Row>
						<Col md={3}>
							<Image
								src={interaction.icon}
								alt={interaction.title}
								className="img-fluid"
							/>
						</Col>
						<Col md={7}>
							<span>{interaction.name}</span>
							{categories}
						</Col>
						<Col md={2}>
							<Link
								to={`/interactions/${interaction.hashid}/preview`}
								className="public-interaction-item-button btn btn-success"
							>
								View
							</Link>
						</Col>
					</Row>
				</Col>
			);
		});
	}

	return (
		<div className="page-public-interactions">
			<Header pageTitle="Public Interactions">
				<Row
					className="header-rounded-inputs"
					style={{ width: "100%" }}
				>
					<Col md={6}>
						<CategoryDropdown action={filterCategory} />
					</Col>
					<Col md={6}>
						<Search
							action={search}
							placeholder="Search for an interaction..."
						/>
					</Col>
				</Row>
			</Header>
			<Container>
				<div className="create-interaction-container">
					<Row>
						<Col md={6}>
							<h1 className="blue-text">Public Interactions</h1>
						</Col>
						<Col md={6} className="vertical-align flex-end">
							<TagFilterDropdown
								public={true}
								onSelectItem={(tag) => filterTag(tag)}
							/>
						</Col>
					</Row>
					<Row>{result}</Row>
					<Pagination
						activePage={activePage}
						itemsCountPerPage={INTERACTION_PAGE_SIZE}
						totalItemsCount={parseInt(interactionCount, 10)}
						pageRangeDisplayed={10}
						innerClass="pagination justify-content-center"
						itemClass="page-item"
						linkClass="page-link"
						onChange={(page) => {
							changePage(page);
						}}
					/>
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default PublicInteractions;
