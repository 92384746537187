import React from "react";
import Loadable from "react-loadable";
import { ApiClient } from "../util/ApiClient.js";

const Loading = () => <div>Loading...</div>;

export default class Interaction {
	constructor(interactionId) {
		this.client = ApiClient();
		this.interactionId = interactionId;
	}

	getInteractionByType(type, hashid = false) {
		let byHashid = "";
		if (hashid) {
			byHashid = "retrieve_by_hashid/";
		}

		return this.client
			.get(`/interactions/${this.interactionId}/${byHashid}`)
			.then((response) => {
				var LoadedComponent = null;
				//TODO: Is there a cleaner way to do this?
				switch (response.data.component) {
					case "drag-and-drop":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-drag-and-drop-editor" */ "igloo-drag-and-drop/src/DragAndDropEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-drag-and-drop" */ "igloo-drag-and-drop/src/DragAndDrop"
									),
								loading: Loading,
							});
						}

						break;
					case "twitter":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-twitter-editor" */ "igloo-twitter/src/TwitterEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-twitter" */ "igloo-twitter/src/Twitter"
									),
								loading: Loading,
							});
						}
						break;
					case "quiz":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-quiz-editor" */ "igloo-quiz/src/QuizEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-quiz" */ "igloo-quiz/src/Quiz"
									),
								loading: Loading,
							});
						}
						break;
					case "reveal":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-reveal-editor" */ "igloo-reveal/src/RevealEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-reveal" */ "igloo-reveal/src/Reveal"
									),
								loading: Loading,
							});
						}
						break;
					case "memory":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-memory-game-editor" */ "igloo-memory-game/src/MemoryGameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-memory-game" */ "igloo-memory-game/src/MemoryGame"
									),
								loading: Loading,
							});
						}
						break;
					case "skillbar":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-skillsbar-editor" */ "igloo-skillsbar/src/SkillsbarEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-skillsbar" */ "igloo-skillsbar/src/Skillsbar"
									),
								loading: Loading,
							});
						}
						break;
					case "slider":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-slider-editor" */ "igloo-slider/src/SliderEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-slider" */ "igloo-slider/src/Slider"
									),
								loading: Loading,
							});
						}
						break;
					case "facebook":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-facebook-editor" */ "igloo-facebook/src/Editor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-facebook" */ "igloo-facebook/src/Facebook"
									),
								loading: Loading,
							});
						}
						break;
					case "flashcard":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-flashcard-editor" */ "igloo-flashcard/src/FlashcardEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-flashcard" */ "igloo-flashcard/src/Flashcard"
									),
								loading: Loading,
							});
						}
						break;
					case "hotspot":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-hotspot-editor" */ "igloo-hotspot/src/HotspotEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-hotspot" */ "igloo-hotspot/src/Hotspot"
									),
								loading: Loading,
							});
						}
						break;
					case "sorting":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-sorting-editor" */ "igloo-sorting/src/SortingEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-sorting" */ "igloo-sorting/src/Sorting"
									),
								loading: Loading,
							});
						}
						break;
					case "dropzone":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-dropzone-editor" */ "igloo-dropzone/src/DropzoneEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-dropzone" */ "igloo-dropzone/src/Dropzone"
									),
								loading: Loading,
							});
						}
						break;
					case "crossword":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-crossword-editor" */ "igloo-crossword/src/CrosswordEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-crossword" */ "igloo-crossword/src/Crossword"
									),
								loading: Loading,
							});
						}
						break;

					case "fill-in-the-blanks":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-fill-in-the-blanks-editor" */ "igloo-fill-in-the-blanks/src/GameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-fill-in-the-blanks" */ "igloo-fill-in-the-blanks/src/Game"
									),
								loading: Loading,
							});
						}
						break;
					case "review":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-review-editor" */ "igloo-review/src/GameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-review" */ "igloo-review/src/Game"
									),
								loading: Loading,
							});
						}
						break;
					case "drag-the-words":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-drag-the-words-editor" */ "igloo-drag-the-words/src/GameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-drag-the-words" */ "igloo-drag-the-words/src/Game"
									),
								loading: Loading,
							});
						}
						break;
					case "self-assessment":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-self-assessment" */ "igloo-self-assessment/src/GameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-self-assessment" */ "igloo-self-assessment/src/Game"
									),
								loading: Loading,
							});
						}
						break;
					case "self-assessment-v2":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-self-assessment-v2" */ "igloo-self-assessment-v2/src/GameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-self-assessment-v2" */ "igloo-self-assessment-v2/src/Game"
									),
								loading: Loading,
							});
						}
						break;
					case "sort-the-tiles":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-sort-the-tiles" */ "igloo-sort-the-tiles/src/GameEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-sort-the-tiles" */ "igloo-sort-the-tiles/src/Game"
									),
								loading: Loading,
							});
						}
						break;
					case "image-sequencing":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-image-sequencing" */ "igloo-image-sequencing/src/IglooEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-image-sequencing" */ "igloo-image-sequencing/src/Igloo"
									),
								loading: Loading,
							});
						}
						break;
					case "jeopardy":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-jeopardy" */ "igloo-jeopardy/src/IglooEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-jeopardy" */ "igloo-jeopardy/src/Igloo"
									),
								loading: Loading,
							});
						}
						break;
					case "timeline":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-timeline" */ "igloo-timeline/src/IglooEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-timeline" */ "igloo-timeline/src/Igloo"
									),
								loading: Loading,
							});
						}
						break;
					case "image-juxtaposition":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-image-juxtaposition" */ "igloo-image-juxtaposition/src/IglooEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-image-juxtaposition" */ "igloo-image-juxtaposition/src/Igloo"
									),
								loading: Loading,
							});
						}
						break;
					case "mark-the-words":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-mark-the-words" */ "igloo-mark-the-words/src/MarkWordsEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-mark-the-words" */ "igloo-mark-the-words/src/MarkWords"
									),
								loading: Loading,
							});
						}
						break;
					case "find-the-words":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-find-the-words" */ "igloo-find-the-words/src/FindWordsEditor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-find-the-words" */ "igloo-find-the-words/src/FindWords"
									),
								loading: Loading,
							});
						}
						break;
					case "word-cloud":
						if (type === "editor") {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-word-cloud" */ "igloo-word-cloud/src/Editor"
									),
								loading: Loading,
							});
						} else {
							LoadedComponent = Loadable({
								loader: () =>
									import(
										/* webpackChunkName: "igloo-word-cloud" */ "igloo-word-cloud/src/Interaction"
									),
								loading: Loading,
							});
						}
						break;

					default:
						console.log("No interaction found");
				}

				return {
					data: response.data,
					module: { loaded: LoadedComponent },
				};
			});
	}

	getInteractionByHashid() {
		return this.getInteractionByType("game", true);
	}

	getInteraction() {
		return this.getInteractionByType("game");
	}

	getInteractionEditor() {
		return this.getInteractionByType("editor");
	}

	saveInteraction(config) {
		return this.client.put(`/interactions/${this.interactionId}/`, {
			config: config,
		});
	}

	clearMeta() {
		return this.client.post(
			`/interactions/${this.interactionId}/clearMeta/`,
		);
	}

	getMeta(hashid) {
		return this.client.get(`/interactions/${hashid}/get_meta`);
	}

	ping(session) {
		let params = {};
		if (session) {
			params["sesh"] = session;
		}

		return this.client
			.put(`/interactions/${this.interactionId}/ping/`, params)
			.then((response) => {
				window.localStorage.setItem(
					"session_" + this.interactionId,
					response.data.sesh,
				);
			});
	}

	saveInteractionTags(tags) {
		return this.client.put(
			`/interactions/${this.interactionId}/updateTags/`,
			{
				tags: tags,
			},
		);
	}
}
