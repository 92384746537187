import {
	object,
	alias,
	deserialize,
	identifier,
	serializable,
	getDefaultModelSchema,
} from "serializr";
import Subscription from "../subscription/Subscription";

export default class Organization {
	static fromJson(json) {
		return deserialize(this, json);
	}

	@serializable(identifier())
	id;

	@serializable(alias("brand_color"))
	brandColor;

	@serializable
	cover;

	@serializable(alias("expiration_date"))
	expiration;

	@serializable(alias("is_active"))
	isActive;

	@serializable
	logo;

	@serializable
	slogan;

	@serializable(alias("storage_used"))
	storageUsed;

	@serializable(alias("total_storage_allocated"))
	storageAllocated;

	@serializable(alias("interactions_created"))
	interactionsCreated;

	@serializable
	subscription;

	@serializable get storageUsedPercent() {
		return (this.storageUsed / this.storageAllocated) * 100;
	}

	constructor(props) {
		if (!props) {
			props = {};
		}

		this.id = props.id;
		this.brandColor = props.brand_color;
		this.cover = props.cover;
		this.expiration = props.expiration_date;
		this.isActive = props.is_active;
		this.logo = props.logo;
		this.slogan = props.slogan;
		this.storageUsed = props.storage_used;
		this.storageAllocated = props.total_storage_allocated;
		this.interactionsCreated = props.interactions_created;
		this.subscription = props.subscription;

		getDefaultModelSchema(Organization).props.subscription = object(
			Subscription,
		);
	}
}
