import { ApiClient } from "../util/ApiClient.js";
import { User } from "./User";

export default class UserStore {
	getUser = async () => {
		const response = await ApiClient().get("user/current/");
		const data = await response.data;
		return User.fromJson(data);
	};

	getStats = async () => {
		const response = await ApiClient().get("user/stats/");
		const data = await response.data;
		return data;
	};

	setActive = (id, isActive) => {
		return ApiClient().put(`/user/${id}/setActive/`, {
			is_active: isActive,
		});
	};

	save = async (changed) => {
		const response = await ApiClient().put(`/user/${changed.id}/`, changed);
		const data = await response.data;
		return User.fromJson(data, "user");
	};
}
