import axios from "axios";
import { LOGIN, REGISTER, URL } from "../config/Api";

import commonStore from "../util/commonStore";

export default class AuthStore {
	login = (username, password) => {
		return axios
			.post(URL + LOGIN, {
				email_or_username: username,
				password: password,
			})
			.then((response) => commonStore.setToken(response.data.token));
	};

	register = (
		first_name,
		last_name,
		email,
		username,
		password,
		inviteKey,
	) => {
		this.inProgress = true;
		this.errors = {};
		return axios.post(URL + REGISTER, {
			first_name,
			last_name,
			email,
			username,
			password,
			invite_key: inviteKey,
		});
	};

	reset = () => {};

	logout = () => {
		commonStore.setToken(undefined);

		return Promise.resolve();
	};
}
