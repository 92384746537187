export const BASEURL =
	process.env.NODE_ENV === "development"
		? "http://localhost:8000"
		: "https://api.igloo.games";
export const URL = BASEURL + "/api";
export const LOGIN = "/login/";
export const REGISTER = "/register/";
export const FORGOT_PASSWORD_URL = "/forgot_password/";
export const RESET_PASSWORD_URL = "/reset_password/";
export const SUBSCRIPTION = "subscription";
export const UNSUBSCRIBE = "unsubscribe";
export const SUBSCRIBE = "/subscribe/";
