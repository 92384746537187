import React from "react";
import "./UserProfile.scss";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import UserForm from "./UserForm";

const UserProfile = () => {
	return (
		<div id="user-profile">
			<Header />
			<UserForm />
			<Footer />
		</div>
	);
};

export default UserProfile;
