import React, { useState } from "react";
import { Row, Container, Form, Col, Button, Alert } from "react-bootstrap";
import Header from "../ui/Header";
import "./RequestInteraction.scss";
import { ApiClient } from "../util/ApiClient";
import Footer from "../ui/Footer";
import { Link } from "react-router-dom";

export const RequestInteraction = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [description, setDescription] = useState("");
	const [errors, setErrors] = useState("");
	const [submitting, setSubmitting] = useState(false);

	document.title = "iglooo - Request Interaction";

	const submitIdea = () => {
		if (!firstName || !lastName || !email || !description) {
			setErrors("Please complete all the fields.");
			return;
		}
		setSubmitting(true);
		setErrors("");

		const client = ApiClient();
		client
			.post("/request-interaction/", {
				firstname: firstName,
				lastname: lastName,
				email: email,
				description: description,
			})
			.then(() => {
				setSubmitting(false);
				setFirstName("");
				setLastName("");
				setEmail("");
				setDescription("");
			});
	};

	return (
		<div id="request-interaction">
			<Header>
				<Row style={{ width: "100%" }}>
					<Col
						className="vertical-align igloo-login-nav text-right"
						style={{ width: "100%", justifyContent: "flex-end" }}
					>
						<Link to="/login" className="btn btn-rounded">
							Log in
						</Link>
						<Link to="/register" className="btn btn-rounded active">
							Sign up
						</Link>
					</Col>
				</Row>
			</Header>
			<Container>
				<img
					src="../images/interaction-step-2.png"
					className="responsive-img text-center header-img"
					alt="Step 2 Add your content"
				/>
				<h1 className="text-center mb-2 mt-5">
					Have an idea for an interaction?
				</h1>
				<h2 className="text-center mb-5">
					Let us know below, we would love to hear about it!
				</h2>
				<Form>
					<Row>
						<Col md={6}>
							<Form.Group controlId="firstname">
								<Form.Label>First name</Form.Label>
								<Form.Control
									name="firstname"
									value={firstName}
									onChange={(event) =>
										setFirstName(event.target.value)
									}
								></Form.Control>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group controlId="lastname">
								<Form.Label>Last name</Form.Label>
								<Form.Control
									value={lastName}
									name="lastname"
									onChange={(event) =>
										setLastName(event.target.value)
									}
								></Form.Control>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group controlId="email">
								<Form.Label>Email</Form.Label>
								<Form.Control
									value={email}
									name="email"
									onChange={(event) =>
										setEmail(event.target.value)
									}
								></Form.Control>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group controlId="description">
								<Form.Label>Interaction description</Form.Label>
								<Form.Control
									value={description}
									as="textarea"
									name="description"
									rows={10}
									onChange={(event) =>
										setDescription(event.target.value)
									}
								></Form.Control>
							</Form.Group>
						</Col>
						{errors !== "" ? (
							<Col md={12}>
								<Alert variant="danger">{errors}</Alert>
							</Col>
						) : (
							""
						)}
						<Col md={12} className="pb-5">
							<Button
								disabled={submitting}
								size="lg"
								variant="success"
								onClick={submitIdea}
							>
								Send Idea
							</Button>
						</Col>
					</Row>
				</Form>
			</Container>
			<Footer />
		</div>
	);
};
