import React from "react";
import Loadable from "react-loadable";
/**
 * Loads the BasicWYSIWYG component asynchronously.
 */

export var LoadableBasicWYSIWYG = Loadable({
  loader: function loader() {
    return import(
    /* webpackChunkName: "quill-wysiwyg" */
    "./BasicWYSIWYG");
  },
  loading: function loading() {
    return null;
  }
});