import React, { useState, useContext } from "react";
import { createContext } from "react";
import MediaStore from "./MediaStore";

const MediaContext = createContext();

const MediaProvider = (props) => {
	const [media, setMedia] = useState();

	const mediaStore = new MediaStore();

	const getMedia = async () => {
		const fetchedMedia = await mediaStore.loadMedia();
		setMedia(fetchedMedia);
	};

	const upload = async (file) => {
		return await mediaStore.upload(file).then(() => {
			getMedia();
		});
	};

	const deleteMedia = async (file) => {
		return await mediaStore.delete(file).then(() => {
			getMedia();
		});
	};

	return (
		<MediaContext.Provider
			value={{
				media,
				getMedia,
				upload,
				deleteMedia,
			}}
		>
			{props.children}
		</MediaContext.Provider>
	);
};

const useMedia = () => {
	return useContext(MediaContext);
};

const MediaConsumer = MediaContext.Consumer;
export { MediaProvider, MediaConsumer, MediaContext, useMedia };
