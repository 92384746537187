import _ from "lodash";
import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import { Col, Container, Row } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import MediaDropzone from "../media/MediaDropzone";
import "./EditInteraction.scss";
import Interaction from "./Interaction.js";

import UseModal from "./UseModal";
import { useParams } from "react-router";
import { useMedia } from "../media/MediaContext.js";

const EditInteraction = () => {
	const [useModalOpen, setUseModalOpen] = useState(false);
	const [config, setConfig] = useState();
	const [interactionModule, setInteractionModule] = useState();
	const [interactionComponent, setInteractionComponent] = useState();

	const [hashid, setHashid] = useState();
	const [tags, setTags] = useState([]);

	const params = useParams();
	const mediaContext = useMedia();

	const interaction = new Interaction(params.id);

	useEffect(() => {
		const int = new Interaction(params.id);
		int.getInteractionEditor().then((response) => {
			setConfig(response.data.config);
			setInteractionModule(response.module);
			setHashid(response.data.hashid);
			setTags(response.data.tags);
			setInteractionComponent(response.data.component);

			document.title =
				"iglooo - Edit -  " + response.data.config.meta.name;
		});
	}, [params.id]);

	if (!mediaContext.media) {
		mediaContext.getMedia();
	}

	const saveInteraction = () => {
		interaction.saveInteraction(config).then((response) => {
			if (response.data.success) {
				if (response.data.redirect) {
					window.location.href = response.data.redirect;
				}
			}
		});
	};

	const clearMeta = () => {
		interaction.clearMeta();
	};

	const downloadMeta = async () => {
		await interaction.getMeta(hashid).then((response) => {
			let data = JSON.parse(response.data);
			if (data.success && data["data"] !== null) {
				const { items } = config;
				let headers = [
					"Item->",
					"Email",
					"CEO (1=yes)",
					"Company (name)",
				];
				let emptyRow = ["Individual", "", "", ""];
				for (let k = 0; k < items.length; k++) {
					headers.push(items[k]["name"]);
					emptyRow.push("");
				}
				let exportData = [];
				exportData.push(headers);
				exportData.push(emptyRow);
				let scores = data["data"]["scores"];

				for (let i = 0; i < scores.length; i++) {
					let arr = [];
					let score = scores[i];
					let answers = score["answers"];
					arr.push(score["user"]);
					arr.push(score["email"]);
					arr.push(score["isCEO"] ? 1 : 0);
					arr.push(score["org"]);
					for (let key in answers) {
						arr.push(answers[key]["point"]);
					}
					exportData.push(arr);
				}
				const ws = XLSX.utils.aoa_to_sheet(exportData, {
					origin: "A2",
				});
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "scores");
				XLSX.writeFile(wb, "scores.xlsx");
			} else {
				toast.info("No scores are available to download.");
			}
		});
	};

	const toggleModal = () => {
		setUseModalOpen(!useModalOpen);
	};

	const handleTagsChange = (tags) => {
		interaction.saveInteractionTags(tags);
		setTags(tags);
	};

	if (!config) {
		return <div className="page-edit-interaction">Loading</div>;
	}

	var interactionId = params.id;

	let medias = { "": "None" };
	_.each(mediaContext.media, (media) => {
		medias[media.url] = media.filename;
	});

	return (
		<div className="page-edit-interaction">
			<Header pageTitle={config.meta.name} />
			<Container fluid>
				<div className="row toolbar">
					<div className="col-sm">
						<button
							onClick={saveInteraction}
							className="btn btn-primary float-right"
						>
							<i className="far fa-save" /> Save
						</button>
						<button
							onClick={toggleModal}
							className="btn btn-primary float-right mr-2"
						>
							<i className="far fa-newspaper" /> Use
						</button>
						{_.indexOf(
							["self-assessment", "self-assessment-v2"],
							interactionComponent,
						) !== -1 && (
							<>
								<button
									onClick={clearMeta}
									className="btn btn-danger float-right mr-2"
								>
									<i className="fas fa-trash-alt" /> Clear
									Scores
								</button>
								<button
									onClick={downloadMeta}
									className="btn btn-success float-right mr-2"
								>
									<i className="fas fa-download" /> Download
									Scores (xlsx)
								</button>
							</>
						)}
					</div>
				</div>

				{interactionModule && (
					<interactionModule.loaded
						config={config}
						media={medias}
						mediaTabContent={
							<div className="interaction-media-wrapper">
								<span className="media-heading">
									Upload Media to use in this interaction:
								</span>
								<MediaDropzone />
							</div>
						}
					/>
				)}

				<div className="interaction-tags-wrapper">
					<TagsInput
						value={tags}
						onChange={handleTagsChange}
						inputProps={{ placeholder: "Enter tag..." }}
						renderLayout={(tagComponents, inputComponent) => {
							return (
								<Row>
									<Col md={6}>
										<span className="tags-heading">
											Help others find your interactions:
										</span>
										<span className="tags-label">
											Select or add tags:
										</span>
										{inputComponent}
									</Col>
									<Col md={6} className="text-center">
										{tagComponents}
									</Col>
								</Row>
							);
						}}
					/>
				</div>

				<UseModal
					interactionId={interactionId}
					interactionHashid={hashid}
					isOpen={useModalOpen}
					onClose={(state) => setUseModalOpen(state)}
				/>
			</Container>
			<Footer />
		</div>
	);
};

export default EditInteraction;
