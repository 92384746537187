import React, { useEffect, useState } from "react";
import { Container, Button, Form, Table, Alert } from "react-bootstrap";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import Loading from "../ui/Loading.js";
import adminProtectedPage from "../util/adminProtectedPage";
import UserStore from "./UserStore";
import { useUsers } from "./UserContext";

import OrganizationStore from "../organization/OrganizationStore";
import Switch from "react-switch";
import "./Team.scss";

const Team = () => {
	const [memberEmail, setMemberEmail] = useState();
	const [team, setTeam] = useState([]);

	const organizationStore = new OrganizationStore();
	const userStore = new UserStore();
	const userContext = useUsers();

	useEffect(() => {
		document.title = "iglooo - My Team";
	}, []);

	if (!team) {
		organizationStore.getCurrentTeam().then((response) => {
			setTeam(response);
		});
	}

	const handleEmailFieldChange = (e) => setMemberEmail(e.target.value);

	const inviteTeamMember = (event) => {
		event.preventDefault();
		organizationStore.invite(memberEmail);
	};

	const setMemberActiveFlag = (id, checked) => {
		userStore.setActive(id, checked).then(() => {
			organizationStore.getCurrentTeam().then((response) => {
				setTeam(response);
			});
		});
	};

	let members = null;
	let subscription;
	let limitNotice;
	let user = userContext.user;
	if (!user) {
		return <Loading />;
	}

	subscription = user.organization.subscription;

	if (team && team.length > 0) {
		if (!subscription.premiumInteractions) {
			limitNotice = (
				<Alert variant="info" style={{ width: "100%" }}>
					With the <strong>{subscription.name}</strong> subscription
					you are able to create{" "}
					<strong>{subscription.userLimit}</strong> team members. You
					have created a total of <strong>{team.length}</strong>{" "}
					members.
				</Alert>
			);
		}

		members = team.map((member, idx) => {
			return (
				<tr key={idx}>
					<td>{member.firstname}</td>
					<td>{member.lastname}</td>
					<td>{member.username}</td>
					<td>{member.email}</td>
					<td className="text-center">
						{member.isAdmin ? (
							"Account Owner"
						) : (
							<Switch
								onChange={(checked) => {
									setMemberActiveFlag(member.id, checked);
								}}
								name={"member-active-" + idx}
								checked={member.isActive}
								id={"member-active-" + idx}
								onColor="#86d3ff"
								onHandleColor="#2693e6"
								handleDiameter={16}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={12}
								width={32}
							/>
						)}
					</td>
				</tr>
			);
		});
	}

	return (
		<div className="page-my-team">
			<Header pageTitle="My Team" />
			<Container className="my-team-container">
				<div className="my-team-members">
					<h3 className="blue-text">My Team Members</h3>
					{limitNotice}
					<Table responsive striped bordered hover variant="dark">
						<thead>
							<tr>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Username</th>
								<th>Email</th>
								<th>Active</th>
							</tr>
						</thead>
						<tbody>{members}</tbody>
					</Table>
				</div>
				{team.length >= subscription.userLimit ? null : (
					<div className="my-team-invite">
						<h3 className="blue-text">Invite Team Members</h3>
						<p>
							Invite your team to your iglooo account, your team
							members will be able to create, modify and use any
							interactions in your account.
						</p>
						<Form onSubmit={inviteTeamMember} method="POST">
							<Form.Group controlId="email">
								<Form.Label>
									Team member email address:
								</Form.Label>
								<Form.Control
									required
									type="email"
									onChange={handleEmailFieldChange}
								/>
							</Form.Group>
							<Button variant="primary" type="submit">
								Send Invite
							</Button>
						</Form>
					</div>
				)}
			</Container>
			<Footer />
		</div>
	);
};

export default adminProtectedPage(Team);
