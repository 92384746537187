import React from "react";

export default function withSnapEngage(WrappedComponent) {
	return class extends React.Component {
		componentDidMount() {
			if (typeof window.SnapEngage == "undefined") {
				(function () {
					var se = document.createElement("script");
					se.type = "text/javascript";
					se.async = true;
					se.src =
						"//storage.googleapis.com/code.snapengage.com/js/ae6f278a-d7f5-4253-9335-0be5fd8e4d5b.js";
					var done = false;
					se.onload = se.onreadystatechange = function () {
						if (
							!done &&
							(!this.readyState ||
								this.readyState === "loaded" ||
								this.readyState === "complete")
						) {
							done = true;
							/* Place your SnapEngage JS API code below */
							/* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
						}
					};
					var s = document.getElementsByTagName("script")[0];
					s.parentNode.insertBefore(se, s);
				})();
			} else {
				window.SnapEngage.showButton();
			}
		}

		componentWillUnmount() {
			window.SnapEngage.hideButton();
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};
}
