import React, { useState, useContext } from "react";
import { createContext } from "react";
import UserStore from "./UserStore";

const UserContext = createContext();

const UserProvider = (props) => {
	const [user, setUser] = useState();
	const [stats, setStats] = useState();

	const userStore = new UserStore();

	const getUser = async () => {
		const fetchedUser = await userStore.getUser();
		setUser(fetchedUser);
	};

	const getStats = async () => {
		const fetchedStats = await userStore.getStats();
		setStats(fetchedStats);
	};

	const saveUser = async (changed) => {
		const fetchedUser = await userStore.save(changed);
		setUser(fetchedUser);
	};

	const forgetUser = () => {
		setUser(null);
	};

	if (!user) {
		getUser();
	}

	return (
		<UserContext.Provider
			value={{
				user,
				stats,
				getStats,
				getUser,
				forgetUser,
				saveUser,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

const useUsers = () => {
	return useContext(UserContext);
};

const UserConsumer = UserContext.Consumer;
export { UserProvider, UserConsumer, UserContext, useUsers };
