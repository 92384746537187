import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer";
import { Link, useHistory } from "react-router-dom";
import CategoryStore from "../category/CategoryStore";

import "./HomeNew.scss";
import Loading from "../ui/Loading.js";

const HomeNew = () => {
	const categoryStore = new CategoryStore();
	const [categories, setCategories] = useState();
	const history = useHistory();

	if (!categories) {
		categoryStore.findAll().then((data) => {
			setCategories(data);

			return <Loading />;
		});
	}

	let categoriesPanels = [];
	if (categories) {
		categoriesPanels = categories.map((category, idx) => {
			const { name, description, interactions } = category;
			return (
				<Col className="igloo-home-category-list">
					<div className="igloo-home-categories-card">
						<h3>{name}</h3>
						<div className="category-description">
							{description}
						</div>
						<div>{categoryInteractions(interactions)}</div>
					</div>
				</Col>
			);
		});
	}

	function categoryInteractions(interactions) {
		return (
			<Row className="category-interaction-items">
				{interactions.map(function (interaction, idx) {
					const { name, icon, hashid } = interaction;
					return (
						<Col
							className="category-interaction-item"
							md={3}
							onClick={() => getPeview(hashid)}
						>
							<img src={icon} alt={name}></img>
							<strong className="interaction-name">{name}</strong>
						</Col>
					);
				})}
			</Row>
		);
	}

	const getPeview = (hashid) => {
		let url = "/interactions/" + hashid + "/preview";
		history.push(url);
	};

	return (
		<div id="home-page-new">
			<Header style={{ height: 600 }}>
				<Row style={{ width: "100%" }}>
					<Col className="vertical-align igloo-login-nav">
						<Link to="/login" className="btn btn-rounded">
							Log in
						</Link>
						<Link to="/register" className="btn btn-rounded active">
							Sign up
						</Link>
					</Col>
				</Row>
			</Header>
			<div className="container">
				<div className="igloo-home-svg-wrap text-center">
					<div className="text-center">
						<img
							className="card-img-top"
							src="../images/iglooo_screen.svg"
							alt="Iglooo Screen"
						/>
					</div>
				</div>
				<div class="categories-container">{categoriesPanels}</div>
			</div>
			<Footer />
		</div>
	);
};

export default HomeNew;
