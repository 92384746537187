import React from "react";
import "./Features.scss";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import { Col, Row } from "react-bootstrap";

const Features = () => {
	return (
		<div id="page-features">
			<Header />
			<div className="igloo-features-section">
				<h4>iglooo Features</h4>
				<Row className="text-center features">
					<Col md={4} sm={6}>
						<img
							src="../images/icon-code.png"
							className="responsive-img"
							alt="No Programming Required"
						/>
						<strong>No Programming Required</strong>
						<p>
							Create amazing interactions using our simple but
							powerful interaction editor.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-html5.png"
							className="responsive-img"
							alt="HTML5 Output"
						/>
						<strong>HTML5 Output</strong>
						<p>
							Publish your interactions as HTML. Our interactions
							work on Windows, Mac, iPads, Android Tables,
							Chromebooks and more.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-realtime.png"
							className="responsive-img"
							alt="Real-time Editing"
						/>
						<strong>Real-time Editing</strong>
						<p>
							Simplify your workflow by using our real-time
							editor. Update your interactions without the need to
							re-export or upload it again.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-nosoftware.png"
							className="responsive-img"
							alt="No Software Required"
						/>
						<strong>No Software Required</strong>
						<p>
							No software download is required to use our cloud
							based editor. Works on Mac, Windows and Linux in all
							modern web browser's.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-scorm-compliant.png"
							className="responsive-img"
							alt="SCORM Compliant"
						/>
						<strong>Works with Articulate Storyline</strong>
						<p>
							Our interactions can be exported and used with
							Articulate Storyline Web Objects.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-embed.png"
							className="responsive-img"
							alt="No Programming Required"
						/>
						<strong>Embed into your Content</strong>
						<p>
							Easily embed your interactions into your own content
							using our embed code.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-scorm-export.png"
							className="responsive-img"
							alt="SCORM Export"
						/>
						<strong>SCORM Export</strong>
						<p>
							Export your interaction as a stand-alone SCORM1.2,
							SCORM2004 or TinCan / xAPI package to import into
							your compliant LMS.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-public.png"
							className="responsive-img"
							alt="Public Library"
						/>
						<strong>Public Library</strong>
						<p>
							Browse the public library of interactions created by
							other users. Mark your own interactions as public to
							share them with others.
						</p>
					</Col>
					<Col md={4} sm={6}>
						<img
							src="../images/icon-team-accounts.png"
							className="responsive-img"
							alt="Team Accounts"
						/>
						<strong>Team Accounts</strong>
						<p>
							Invite other users from your company to help build
							and edit your interactions.
						</p>
					</Col>
				</Row>
			</div>
			<Footer />
		</div>
	);
};

export default Features;
