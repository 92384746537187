import React, { useState, useEffect } from "react";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import {
	faCircle as solidCircle,
	faFilter,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import DropdownWithCaret from "./DropdownWithCaret";
import "./TagFilterDropdown.scss";
import TagStore from "../tag/TagStore";

const TagFilterDropdown = (props) => {
	const [selected, setSelected] = useState();
	const [tags, setTags] = useState();

	useEffect(() => {
		const tagStore = new TagStore();

		if (props.public) {
			tagStore.loadPublicTags().then((tags) => {
				setTags(tags);
			});
		} else {
			tagStore.loadMyTags().then((tags) => {
				setTags(tags);
			});
		}
	}, [props.public]);

	const selectTag = (tag) => {
		setSelected(tag);
		props.onSelectItem(tag);
	};

	let tagElements = "";
	if (!tags) {
		tagElements = <span>No Tags found</span>;
	} else {
		tagElements = tags.map((tag, idx) => {
			let icon = (
				<FontAwesomeIcon
					size="sm"
					icon={selected === tag ? solidCircle : faCircle}
					className={
						selected === tag
							? "tag-icon-selected"
							: "tag-icon-default"
					}
				/>
			);

			return (
				<div key={idx}>
					<Dropdown.Item onClick={() => selectTag(tag)}>
						{icon} {tag}
					</Dropdown.Item>
					<Dropdown.Divider />
				</div>
			);
		});
	}

	return (
		<div className="tag-filter-dropdown">
			<DropdownWithCaret
				title={
					<span className="filter-button">
						<FontAwesomeIcon icon={faFilter} />
						<span className="filter-text">Filter</span>
					</span>
				}
			>
				<Dropdown.Item onClick={() => selectTag(null)}>
					<FontAwesomeIcon
						size="sm"
						icon={selected == null ? solidCircle : faCircle}
						className={
							selected == null
								? "tag-icon-selected"
								: "tag-icon-default"
						}
					/>
					&nbsp;All
				</Dropdown.Item>
				<Dropdown.Divider />
				{tagElements}
			</DropdownWithCaret>
		</div>
	);
};

export default TagFilterDropdown;
