import axios from "axios";
import { URL } from "../config/Api";
import commonStore from "./commonStore";
import { toast } from "react-toastify";

export const ApiClient = function () {
	const token = commonStore.getToken();
	const params = {
		baseURL: URL,
		headers: { Authorization: "Token " + token },
	};

	let axiosInstance = axios.create(params);

	axiosInstance.interceptors.response.use(
		function (response) {
			if (response.data) {
				try {
					let responseJson = JSON.parse(response.data);
					if (responseJson.success && responseJson.message) {
						toast.success(responseJson.message);
					}
				} catch (e) {}
			}

			return response;
		},
		function (error) {
			if (error.response) {
				toast.error(error.response.data);
			} else {
				toast.error("There was an error loading the website.");
			}
			// Do something with response error
			return Promise.reject(error);
		},
	);

	return axiosInstance;
};
