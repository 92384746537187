export const embedURL =
	process.env.NODE_ENV === "development"
		? "https://gameo-embed.ngrok.io/embed.js"
		: "https://embed.iglooo.io/embed.js";

export const INTERACTION_PAGE_SIZE = 15;
export const FREE_SUBSCRIPTION_NAME = "Free Forever";

export const SENTRY_DSN =
	"https://7e435b9f872f457c99224c6e71d30e91@sentry.io/1512732";

export const HOMEPAGE_INTERACTION_HASHID =
	process.env.NODE_ENV === "development" ? "QbYEl0bz" : "YRdG7dDz";

// Media Settings
export const MEDIA_ACCEPTED_FORMATS = [
	"image/jpeg",
	"image/png",
	"image/gif",
	"image/svg+xml",
];
export const MEDIA_ACCEPTED_FORMATS_STRING = MEDIA_ACCEPTED_FORMATS.join(",");
export const MEDIA_MAX_FILESIZE = 4194304;
