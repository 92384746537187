import React, { useEffect, useState } from "react";
import commonStore from "../util/commonStore";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import Interaction from "./Interaction.js";
import { Link, useParams, useHistory } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { ApiClient } from "../util/ApiClient";
import Loading from "../ui/Loading";
import "./PreviewInteraction.scss";

const PreviewInteraction = () => {
	const [cloning, setCloning] = useState(false);
	const [isPublic, setIsPublic] = useState(false);
	const [config, setConfig] = useState();
	const [interactionModule, setInteractionModule] = useState();
	const [interactionId, setInteractionId] = useState();

	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		const interaction = new Interaction(params.hashid);

		interaction.getInteractionByHashid().then((response) => {
			setIsPublic(response.data.is_public);
			setConfig(response.data.config);
			setInteractionModule(response.module);
			setInteractionId(response.data.id);
			document.title = "iglooo - " + response.data.config.meta.name;
		});
	}, [params.hashid]);

	const cloneInteraction = () => {
		setCloning(true);

		ApiClient()
			.post(`/interactions/${interactionId}/clone/`)
			.then((response) => {
				this.setState({ cloning: false });
				setCloning(false);
				history.replace(`/interactions/${response.data.id}/edit`);
			});
	};

	let cloneButton = null;

	let result = (
		<div className="common-loading">
			<Loading /> <span>Loading Preview...</span>
		</div>
	);
	if (config && !cloning) {
		result = interactionModule && (
			<interactionModule.loaded config={config} media={[]} />
		);

		if (isPublic && commonStore.getToken()) {
			cloneButton = (
				<Button
					variant="success"
					onClick={cloneInteraction}
					className="float-right"
				>
					Create
				</Button>
			);
		} else if (isPublic && !commonStore.getToken()) {
			cloneButton = (
				<Link to="/register" className="float-right btn btn-success">
					Get Started
				</Link>
			);
		}
	}

	if (cloning) {
		result = (
			<div className="common-loading">
				<Loading /> <span>Your Interaction is Being Generated...</span>
			</div>
		);
	}

	return (
		<div id="page-preview-interaction">
			<Header>
				<span className="preview-header-h1">Preview Interaction</span>
			</Header>
			<div className="preview-info">
				<Container>
					<h2 className="blue-text preview-header">
						{config ? config.meta.name : ""}
						{cloneButton}
					</h2>
				</Container>
			</div>

			<Container>
				<div
					style={{
						backgroundColor: config
							? config.preview["background_color"]
							: "",
						padding: 40,
					}}
				>
					{result}
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default PreviewInteraction;
