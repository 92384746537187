import React, { useState } from "react";
import "./Home.scss";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import { HOMEPAGE_INTERACTION_HASHID } from "../config/Settings";
import { Link } from "react-router-dom";
import withSnapEngage from "../util/SnapEngage";
import Interaction from "../interaction/Interaction.js";
import DragAndDrop from "igloo-drag-and-drop/src/DragAndDrop";
import { Row, Col, Container, Image } from "react-bootstrap";
import IglooLogo from "../ui/IglooLogo.js";
import { useUsers } from "../auth/UserContext";

const Home = () => {
	const [embedConfig, setEmbedConfig] = useState();

	const interaction = new Interaction(HOMEPAGE_INTERACTION_HASHID);
	const userContext = useUsers();
	const user = userContext.user;

	document.title = "iglooo - eLearning Interaction Builder";
	if (!embedConfig) {
		interaction.getInteractionByHashid().then((response) => {
			setEmbedConfig(response.data.config);
		});
	}

	let interactionComponent = null;
	if (embedConfig) {
		interactionComponent = <DragAndDrop config={embedConfig} />;
	}

	let headerLinks = [
		<>
			<Link to="/premium" className="btn btn-rounded btn-premium">
				Get Premium
			</Link>
			<Link to="/dashboard" className="btn btn-rounded btn-regular">
				Dashboard
			</Link>
			<Link
				to="/interactions/create"
				className="btn btn-rounded btn-regular"
			>
				Create
			</Link>
		</>,
	];

	if (user) {
		headerLinks = [
			<>
				<Link to="/dashboard" className="btn btn-rounded">
					Dashboard
				</Link>
				<Link to="/interactions/create" className="btn btn-rounded">
					Create
				</Link>
			</>,
		];
	}

	return (
		<div id="page-home">
			<Header showNavbar={false} />
			<header className="igloo-homepage-header">
				<Row>
					<Col className="vertical-align igloo-logo">
						<IglooLogo fill="#2e88ed" width="180px" />{" "}
						<span className="igloo-logo-text">iglooo</span>
					</Col>
					<Col className="vertical-align igloo-homepage-header-nav">
						{headerLinks}
					</Col>
				</Row>
			</header>
			<Container fluid={true}>
				<Row className="igloo-intro-section">
					<Col md={5} className="igloo-intro">
						<p>
							Start creating rich interactive content for your
							eLearning. It's free and easy to get started.
						</p>
						<h1>Build Your Own eLearning Games</h1>
						<p>
							Create, share and reuse interactive HTML5 eLearning
							games.
						</p>
						<Link to="/register" className="igloo-start-btn">
							Get Started
						</Link>
					</Col>
					<Col md={7} className="igloo-demo">
						<Row style={{ height: "100%", display: "flex" }}>
							<Col md={7} sm={12}>
								{interactionComponent}
							</Col>
							<Col md={5} className="d-none d-md-block">
								<Image
									src="/images/home-demo-background.png"
									className="accounting-image img-fluid"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<div className="igloo-library-section">
					<h2>
						Boost your eLearning with free, easy-to-make
						interactions.
					</h2>
					<a
						href="/library"
						className="btn-rounded igloo-library-section-button"
					>
						Examples
					</a>
				</div>
				<div className="igloo-app-screen">
					<Row className="text-center">
						<Col>
							<img
								src="../images/home-igloo-app.png"
								className="app-image"
								alt="iglooo screen capture"
							/>
						</Col>
					</Row>
				</div>

				<div className="igloo-steps-section">
					<Row className="text-center igloo-steps-img">
						<Col className="img-padding">
							<img
								src="../images/interaction-step-1.png"
								className="responsive-img"
								alt="Step 1 Select an interaction"
							/>
							<strong>Select an interaction</strong>
						</Col>
						<Col className="img-padding">
							<img
								src="../images/interaction-step-2.png"
								className="responsive-img"
								alt="Step 2 Add your content"
							/>
							<strong>Add your content</strong>
						</Col>
						<Col className="">
							<img
								src="../images/interaction-step-3.png"
								className="responsive-img"
								alt="Step 3 Share in your course"
							/>
							<strong>Share in your course</strong>
						</Col>
						<Col lg={12}>
							<a
								href="/register"
								className="btn-rounded igloo-steps-section-button"
							>
								START HERE
							</a>
						</Col>
					</Row>
				</div>

				<div className="igloo-examples-section">
					<Row className="text-center">
						<Col md={6} className="examples-pane">
							<p>
								Select from the following interactions made with
								iglooo:
							</p>
							<Row className="igloo-examples-table">
								<table>
									<tbody>
										<tr>
											<td className="number-cell">
												<a href="https://iglooo.io/interactions/YRdG7dDz/preview">
													01
												</a>
											</td>
											<td className="text-cell">
												<a href="https://iglooo.io/interactions/YRdG7dDz/preview">
													<h4>
														Drag and Drop Quizzes
													</h4>
													<h5>
														Text or image based drag
														and drop quizzes
													</h5>
												</a>
											</td>
										</tr>
										<tr>
											<td className="number-cell">
												<a href="https://iglooo.io/interactions/Jxbojagw/preview">
													02
												</a>
											</td>
											<td className="text-cell">
												<a href="https://iglooo.io/interactions/Jxbojagw/preview">
													<h4>Memory Games</h4>
													<h5>
														The classic image
														pairing game
													</h5>
												</a>
											</td>
										</tr>
										<tr>
											<td className="number-cell">
												<a href="https://iglooo.io/interactions/LDdwRb1Y/preview">
													03
												</a>
											</td>
											<td className="text-cell">
												<a href="https://iglooo.io/interactions/LDdwRb1Y/preview">
													<h4>Image Hotspots</h4>
													<h5>
														Create an image with
														multiple info hotspots
													</h5>
												</a>
											</td>
										</tr>
										<tr>
											<td className="number-cell">
												<a href="https://iglooo.io/interactions/l9avmeG1/preview">
													04
												</a>
											</td>
											<td className="text-cell">
												<a href="https://iglooo.io/interactions/l9avmeG1/preview">
													<h4>Flashcards</h4>
													<h5>
														Create stylish and
														modern flashcards
													</h5>
												</a>
											</td>
										</tr>
										<tr>
											<td className="number-cell">
												<a href="https://iglooo.io/interactions/QBeXWdyK/preview">
													05
												</a>
											</td>
											<td className="text-cell">
												<a href="https://iglooo.io/interactions/QBeXWdyK/preview">
													<h4>Arithmetic Quizzess</h4>
													<h5>
														Create time-based
														arithmetic quizzes
													</h5>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</Row>
							<Row className="examples-pane-button">
								<a
									href="/library"
									className="btn-rounded igloo-examples-section-button"
								>
									More Examples
								</a>
							</Row>
						</Col>
						<Col md={6} className="interaction-pane">
							{interactionComponent}
						</Col>
					</Row>
				</div>

				<div className="igloo-features-section">
					<Row className="features-description row-invert-mobile">
						<Col className="features-description-text">
							<strong>Create fun and engaging eLearning</strong>
							<br />
							<br />
							<p>
								iglooo makes it easy to create and share
								HTML5-based eLearning interactions to make your
								online courses fun and highly engaging. You
								don't need to be a programmer, as iglooo
								empowers you to produce meaningful and
								interactive elements to supercharge the learning
								experience.
							</p>
						</Col>
						<Col className="features-description-img">
							<img
								src="../images/igloo-features-create.png"
								className="responsive-img"
								alt="Create interactions"
							/>
						</Col>
					</Row>
					<Row className="features-description">
						<Col className="features-description-img">
							<img
								src="../images/igloo-features-mobile.png"
								className="responsive-img"
								alt="Responsive apps"
							/>
						</Col>
						<Col className="features-description-text">
							<strong>Make responsive interactions</strong>
							<br />
							<br />
							<p>
								With iglooo interactions, users will experience
								the same dynamic and mobile-friendly content on
								computers, smartphones and tablets.
							</p>
						</Col>
					</Row>
					<Row className="features-description row-invert-mobile">
						<Col className="features-description-text">
							<strong>Easy to export</strong>
							<br />
							<br />
							<p>
								iglooo was designed specifically for the{" "}
								<a href="https://znanja.com/">Velsoft LMS</a>,
								but it can be used with all HTML5-based learning
								management systems. With iglooo, you can create
								and edit interactive eLearning activities and
								easily export them to your eLearning courses. No
								special plugins are required and iglooo even
								allows you to edit your interactivities in
								real-time.
							</p>
						</Col>
						<Col className="features-description-img">
							<img
								src="../images/igloo-features-share.png"
								className="responsive-img"
								alt="Share interactions"
							/>
						</Col>
					</Row>
				</div>
			</Container>

			<Footer />
		</div>
	);
};

export default withSnapEngage(Home);
