import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { BASEURL } from "../config/Api";
import Loading from "../ui/Loading.js";

import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from "react-google-maps";

import "./Stats.scss";

const StatsMap = (props) => {
	const [geo, setGeo] = useState();
	const [total, setTotal] = useState(0);
	let totalEl;

	useEffect(() => {
		document.title = "iglooo - Stats";
	}, []);

	const getStats = () => {
		let orgParam = props.orgId ? "?orgId=" + props.orgId : "";
		return axios.get(BASEURL + "/stats/" + orgParam).then((response) => {
			setTotal(response.data.total_minutes);
			setGeo(response.data.geo);
		});
	};

	let result = (
		<div className="common-loading">
			<Loading /> <span>Generating Statistics...</span>
		</div>
	);

	if (!geo) {
		getStats();
		return result;
	}

	if (geo.length) {
		let markers = geo.map((geo, idx) => {
			return (
				<Marker
					key={idx}
					position={{ lat: geo.latitude, lng: geo.longitude }}
				/>
			);
		});

		const MapComponent = withScriptjs(
			withGoogleMap(() => (
				<GoogleMap defaultZoom={2} defaultCenter={{ lat: 30, lng: 0 }}>
					{markers}
				</GoogleMap>
			)),
		);

		totalEl = (
			<h2 className="total-stats-header">
				{total.toLocaleString("en-US")} minutes spent in Igloo
				interactions from all over the World.
			</h2>
		);

		result = (
			<div>
				{totalEl}
				<MapComponent
					googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAkFtFwhP2fEWRqOxg7FJLLOznqwy10Kz0&v=3.exp&libraries=geometry,drawing,places"
					loadingElement={<div style={{ height: "100%" }} />}
					containerElement={
						<div style={{ height: "calc(100vh - 150px)" }} />
					}
					mapElement={<div style={{ height: "100%" }} />}
				/>
			</div>
		);
	}

	return <Container fluid>{result}</Container>;
};

export default StatsMap;
