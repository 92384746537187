import {
	faArrowCircleRight,
	faTrashAlt,
	faCopy,
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, withRouter } from "react-router-dom";
import Switch from "react-switch";
import InteractionListItem from "../interaction/InteractionListItem";
import UseModal from "../interaction/UseModal";
import CategoryDropdown from "../ui/CategoryDropdown";
import DropdownWithCaret from "../ui/DropdownWithCaret";
import { INTERACTION_PAGE_SIZE } from "../config/Settings";
import Header from "../ui/Header";
import Footer from "../ui/Footer.js";
import Loading from "../ui/Loading";
import Search from "../ui/Search";
import TagFilterDropdown from "../ui/TagFilterDropdown";
import { ApiClient } from "../util/ApiClient.js";
import Pagination from "react-js-pagination";
import "./dashboard.scss";
import { useUsers } from "../auth/UserContext";
import InteractionStore from "../interaction/InteractionStore";

const Dashboard = (props) => {
	const [useInteraction, setUseInteraction] = useState();
	const [useModalOpen, setUseModalOpen] = useState(false);
	const [interactions, setInteractions] = useState();
	const [interactionCount, setInteractionCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [activePage, setActivePage] = useState(1);
	const [category, setCategory] = useState();
	const [tag, setTag] = useState();
	const [reload, setReload] = useState();

	const userContext = useUsers();

	useEffect(() => {
		document.title = "iglooo - My Dashboard";
	}, []);

	useEffect(() => {
		const interactionStore = new InteractionStore();

		interactionStore
			.loadMyInteractions(searchTerm, category, tag, activePage)
			.then((response) => {
				setInteractions(response.interactions);
				setInteractionCount(response.interactionCount);
			});
	}, [activePage, searchTerm, category, tag, reload]);

	if (!userContext.stats) {
		userContext.getStats();
	}

	const editInteraction = (interactionId) => {
		props.history.replace("/interactions/" + interactionId + "/edit");
	};

	const search = (term) => {
		setSearchTerm(term);
		setActivePage(1);
	};

	const changePage = (page) => {
		setActivePage(page);
	};

	const filterCategory = (category) => {
		setCategory(category);
	};

	const filterTag = (tag) => {
		setTag(tag);
	};

	const toggleModal = (interaction) => {
		setUseModalOpen(!useModalOpen);
		setUseInteraction(interaction);
	};

	const deleteInteraction = (interaction) => {
		confirmAlert({
			title: "Confirm to delete",
			message:
				"Are you sure you want to delete " +
				interaction.name +
				"? This action cannot be undone.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						ApiClient()
							.delete(`interactions/${interaction.id}`)
							.then(() => {
								setReload(!reload);
							});
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const makeInteractionPublic = (event, checked, interaction) => {
		event.preventDefault();
		event.stopPropagation();

		ApiClient()
			.put(`interactions/${interaction.id}/setPublic/`, {
				is_public: checked,
			})
			.then(() => {
				setReload(!reload);
				userContext.getStats();
			});
	};

	const cloneinteraction = (id) => {
		ApiClient()
			.post(`/interactions/${id}/clone/?clone=true`)
			.then(() => {
				setReload(!reload);
			});
	};

	const stats = userContext.stats;
	const user = userContext.user;

	const loading = <Loading />;
	let result = (
		<div className="common-loading">
			<Loading /> <span>Loading your interactions...</span>
		</div>
	);

	let useModal = null;

	if (interactions && interactions.length > 0) {
		result = interactions.map((interaction, idx) => {
			let menu = (
				<DropdownWithCaret
					title={
						<FontAwesomeIcon
							icon={faEllipsisV}
							className="interaction-options-link"
						/>
					}
				>
					<Dropdown.Item
						onClick={() => deleteInteraction(interaction)}
					>
						Delete
						<FontAwesomeIcon icon={faTrashAlt} />
					</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item
						onClick={() => cloneinteraction(interaction.id)}
					>
						Clone
						<FontAwesomeIcon icon={faCopy} />
					</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item onClick={() => toggleModal(interaction)}>
						Use
						<FontAwesomeIcon icon={faArrowCircleRight} />
					</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item>
						Public
						<Switch
							onChange={(checked, event) =>
								makeInteractionPublic(
									event,
									checked,
									interaction,
								)
							}
							name={"make-public-" + idx}
							checked={interaction.is_public}
							id={"make-public-" + idx}
							onColor="#86d3ff"
							onHandleColor="#2693e6"
							handleDiameter={16}
							uncheckedIcon={false}
							checkedIcon={false}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={12}
							width={32}
							className="float-right"
						/>
					</Dropdown.Item>
				</DropdownWithCaret>
			);

			return (
				<InteractionListItem interaction={interaction} key={idx}>
					<div className="button-group">
						<Link
							to={
								"/interactions/" +
								interaction.hashid +
								"/preview"
							}
							className="btn btn-secondary btn-rounded"
						>
							PREVIEW
						</Link>
						<Button
							className="btn-rounded"
							variant="success"
							onClick={() => editInteraction(interaction.id)}
						>
							EDIT
						</Button>
						{menu}
					</div>
				</InteractionListItem>
			);
		});

		useModal = (
			<UseModal
				interactionId={useInteraction ? useInteraction.id : null}
				interactionHashid={
					useInteraction ? useInteraction.hashid : null
				}
				isOpen={useModalOpen}
				onClose={(state) => setUseModalOpen(state)}
			/>
		);
	} else {
		let message = <p>You haven't created any interactions</p>;
		let btnMessage = "Create Your First Interaction";

		if (category) {
			message = (
				<p>You haven't created any interactions in this category</p>
			);
			btnMessage = "Create an Interaction";
		}

		if (searchTerm) {
			message = <p>No interactions were found for "{searchTerm}"</p>;
			btnMessage = "Create an Interaction";
		}

		if (interactions) {
			result = (
				<div className="text-center no-interactions">
					{message}
					<Link
						to="/interactions/create"
						className="btn btn-success btn-rounded"
					>
						{btnMessage}
					</Link>
				</div>
			);
		}
	}

	return (
		<div className="page-dashboard">
			<Header pageTitle="Dashboard">
				<Col md={5} className="d-none d-sm-inline">
					<span className="user-welcome">
						Welcome Back, {user ? user.firstname : ""}
					</span>
					<span className="user-create-interaction d-sm-none d-none d-md-inline">
						Would you like to create a new interaction...
					</span>
				</Col>
				<Col md={6}>
					<Link
						className="btn btn-light btn-white-rounded"
						to="/interactions/create"
					>
						Create
					</Link>
				</Col>
			</Header>
			<Container className="dashboard-stats-container" fluid>
				<Container>
					<Row>
						<Col
							md={3}
							xs={6}
							className="text-center dashboard-stat-container"
						>
							<span className="dashboard-stat">
								{stats ? stats.total_interactions : loading}
							</span>
							<span className="dashboard-stat-title">
								Interactions
							</span>
							<span className="dashboard-stat-description">
								created so far
							</span>
							<Link
								className="btn btn-success btn-rounded"
								to="/interactions/create"
							>
								Create
							</Link>
						</Col>
						<Col
							md={3}
							xs={6}
							className="text-center dashboard-stat-container"
						>
							<span className="dashboard-stat">
								{stats ? stats.total_minutes : loading}
							</span>
							<span className="dashboard-stat-title">
								Minutes by others
							</span>
							<span className="dashboard-stat-description">
								spent in your interactions
							</span>
							<Link
								className="btn btn-success btn-rounded"
								to="/my-stats"
							>
								VIEW
							</Link>
						</Col>
						<Col
							md={6}
							xs={12}
							className="vertical-align dashboard-right-stats"
						>
							<Container>
								<Row>
									<Col
										xs={4}
										className="dashboard-stat-container"
									>
										<span>
											<strong>
												{stats
													? stats.total_followers
													: loading}
												&nbsp;
											</strong>
											followers
										</span>
										<span>
											<strong>
												{stats
													? stats.total_following
													: loading}
												&nbsp;
											</strong>
											following
										</span>
									</Col>
									<Col
										xs={8}
										className="dashboard-stat-container"
									>
										<span>
											<strong>
												{stats
													? stats.total_public_interactions
													: loading}
												&nbsp;
											</strong>
											interactions made public
										</span>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</Container>
			<Container>
				<Row className="header-rounded-inputs vertical-align">
					<Col md={3}>
						<h2 className="blue-text">My Interactions</h2>
					</Col>
					<Col md={2} className="dashboard-filters">
						<div className="float-right">
							<TagFilterDropdown
								onSelectItem={(tag) => filterTag(tag)}
							/>
						</div>
					</Col>
					<Col md={5}>
						<Search
							action={search}
							placeholder="Search MY interactions..."
						/>
					</Col>
					<Col md={2}>
						<CategoryDropdown action={filterCategory} />
					</Col>
				</Row>
				{result}
				{interactions && interactions.length > 0 ? (
					<Pagination
						activePage={activePage}
						itemsCountPerPage={INTERACTION_PAGE_SIZE}
						totalItemsCount={parseInt(interactionCount, 10)}
						pageRangeDisplayed={10}
						innerClass="pagination justify-content-center"
						itemClass="page-item"
						linkClass="page-link"
						onChange={(page) => {
							changePage(page);
						}}
					/>
				) : (
					""
				)}
			</Container>
			{useModal}
			<Footer />
		</div>
	);
};

export default withRouter(Dashboard);
