import { ApiClient } from "../util/ApiClient.js";

class MediaStore {
	loadMedia = async () => {
		const response = await ApiClient().get("media/");
		const data = await response.data;
		return data;
	};

	delete = async (id) => {
		return await ApiClient().delete("media/" + id + "/");
	};

	upload = async (file) => {
		var formData = new FormData();
		formData.append("file", file);

		return await ApiClient().post("media/", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	};
}

export default MediaStore;
