import React from "react";
import { ButtonToolbar, DropdownButton } from "react-bootstrap";
import "./DropdownWithCaret.scss";

const DropdownWithCaret = (props) => {
	const { title, direction } = props;

	return (
		<ButtonToolbar className="igloo-dropdown-with-caret">
			<DropdownButton
				title={title || "Filter"}
				drop={direction || "left"}
				variant="link"
				id="dd-toggle"
			>
				{props.children}
			</DropdownButton>
		</ButtonToolbar>
	);
};

export default DropdownWithCaret;
