import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import "./login.scss";
import withSnapEngage from "../util/SnapEngage";
import AuthStore from "./AuthStore";
import { useUsers } from "./UserContext";

const Login = (props) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState("");
	const [inProgress, setInProgress] = useState(false);
	const authStore = new AuthStore();
	const userContext = useUsers();

	document.title = "iglooo - Login";

	const handleSubmit = (event) => {
		event.preventDefault();
		setInProgress(true);

		authStore.login(username, password).then(
			() => {
				setInProgress(false);
				userContext.getUser();
				props.history.replace("/dashboard");
			},
			() => {
				setInProgress(false);

				setErrors({
					invalid: "Invalid username or password supplied.",
				});
			},
		);
	};

	return (
		<div className="page-login">
			<Header>
				<Row style={{ width: "100%" }}>
					<Col className="vertical-align igloo-login-nav">
						<Link to="/login" className="btn btn-rounded">
							Log in
						</Link>
						<Link to="/register" className="btn btn-rounded active">
							Sign up
						</Link>
					</Col>
				</Row>
			</Header>
			<div className="login-container container-fluid">
				<div className="frow row">
					<div className="col-sm">
						<h1> Login to your iglooo Account </h1>
						<form
							onSubmit={handleSubmit}
							method="POST"
							id="signup-form"
						>
							<Form.Group controlId="username">
								<Form.Label>
									Email address or Username
								</Form.Label>
								<Form.Control
									required
									type="text"
									value={username}
									isInvalid={errors.username}
									onChange={(e) =>
										setUsername(e.target.value)
									}
								/>
							</Form.Group>

							<Form.Group controlId="password">
								<Form.Label>Password</Form.Label>
								<Form.Control
									required
									type="password"
									value={password}
									isInvalid={errors.password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</Form.Group>
							<p>{errors.invalid}</p>

							<div className="button">
								<button
									className="common-gradient"
									disabled={inProgress}
								>
									<span>Login</span>
								</button>
								<Link to="/forgot-password">
									Forgot your password?
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default withRouter(withSnapEngage(Login));
