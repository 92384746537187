import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.scss";
import Sidebar from "./Sidebar.js";
import IglooLogo from "./IglooLogo";
import { useUsers } from "../auth/UserContext";

const Header = (props) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const userContext = useUsers();
	const user = userContext.user;

	let links = [];
	let loggedInHeader = null;
	let logoLink = "/";

	if (!user) {
		links.push(
			<li key="link-login">
				<Link className="btn-rounded" to="/login">
					Log in
				</Link>
			</li>,
		);
		links.push(
			<li key="link-signup">
				<Link className="btn-rounded" to="/sign-up">
					Sign up
				</Link>
			</li>,
		);
	} else {
		logoLink = "/dashboard";
		let premiumLink;

		if (!user.organization.subscription.premiumInteractions) {
			premiumLink = (
				<Link
					to="/subscribe"
					className="premium-link btn btn-success btn-rounded"
				>
					Get Premium
				</Link>
			);
		}

		loggedInHeader = (
			<div className="header-loggedIn">
				<FontAwesomeIcon
					icon={faBars}
					size="lg"
					onClick={() => setIsSidebarOpen(!isSidebarOpen)}
				/>
				<span className="page-title blue-text">{props.pageTitle}</span>
				<span className="header-username d-none d-md-inline">
					{premiumLink}
					<Link to="/profile">
						{user.firstname} {user.lastname}
					</Link>
				</span>
			</div>
		);
	}

	let navbar = "";
	if (props.showNavbar !== false) {
		navbar = (
			<Navbar className="main-nav common-gradient">
				<Container>
					<Row className="header-row">
						<Col md={4} sm={2} xs={4} className="vertical-align">
							<Navbar.Brand>
								<Link to={logoLink}>
									<IglooLogo fill="#fff" width="145" />{" "}
									<span className="d-sm-none d-none d-md-inline">
										iglooo
									</span>
								</Link>
							</Navbar.Brand>
						</Col>
						<Col md={8} sm={10} xs={8} className="vertical-align">
							{props.children}
						</Col>
					</Row>
				</Container>
			</Navbar>
		);
	}

	return (
		<div>
			<Sidebar
				isOpen={isSidebarOpen}
				onStateChange={(state) => setIsSidebarOpen(state.isOpen)}
			/>
			{loggedInHeader}
			{navbar}
		</div>
	);
};

export default Header;
