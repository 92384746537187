import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Premium.scss";
import Footer from "../ui/Footer.js";
import { useUsers } from "./UserContext.js";
import Header from "../ui/Header.js";

const Premium = () => {
	const userContext = useUsers();

	const handleSeePlanClick = () => {
		window.scroll({
			behavior: "smooth",
			top: document.getElementById("plan").offsetTop,
		});
	};

	let linkHref = "#";

	return (
		<div id="premium-page">
			<div className="igloo-premium">
				<Header />
				<section className="igloo-premium-body">
					<Container>
						<h1>Go Premium and Get More.</h1>

						<div className="igloo-premium-first">
							<h2 className="igloo-premium-firstheader">
								Try Premium free for 30 days. Only $9.99/month
								after.*
							</h2>

							<div className="igloo-premium-buttons">
								<Link
									to={
										userContext.user
											? "/subscribe"
											: "/register?premium=true"
									}
									className="btn-rounded-success text-uppercase"
								>
									Start Free Trial
								</Link>

								<a
									href={linkHref}
									className="btn-rounded text-uppercase"
									onClick={handleSeePlanClick}
								>
									See Plans
								</a>
							</div>

							<p className="igloo-premium-terms">
								*Terms and conditions apply. Open only to users
								who haven't already tried Premium.
							</p>
						</div>
					</Container>
				</section>
			</div>

			<div>
				<h2 className="igloo-premium-why">Why go Premium?</h2>

				<Row className="igloo-premium-benefits">
					<Col md={{ span: 2, offset: 2 }} sm={6}>
						<Image src="/svg/true_false.svg" />
						<div className="igloo-premium-benefit">
							Unlimited Interactions
						</div>
						<span className="igloo-premium-benefitsub">
							No limit to how many interactions you can create.
						</span>
					</Col>
					<Col md={2} sm={6}>
						<Image src="/svg/matching.svg" />
						<div className="igloo-premium-benefit">
							Exclusive Interactions
						</div>
						<span className="igloo-premium-benefitsub">
							Access to our exclusive premium interactions.
						</span>
					</Col>
					<Col md={2} sm={6}>
						<Image src="/svg/memory.svg" />
						<div className="igloo-premium-benefit">
							Team Accounts
						</div>
						<span className="igloo-premium-benefitsub">
							Add up to 20 team members.
						</span>
					</Col>
					<Col md={2} sm={6}>
						<Image src="/svg/multiple_choice.svg" />
						<div className="igloo-premium-benefit">
							No Advertising
						</div>
						<span className="igloo-premium-benefitsub" />
					</Col>
				</Row>
			</div>

			<div id="plan" className="igloo-premium-plansdiv">
				<Row>
					<Col
						lg={{ span: 6, offset: 3 }}
						md={{ span: 10, offset: 1 }}
						xs={{ span: 10, offset: 1 }}
						className="igloo-premium-plan"
					>
						<h5 className="igloo-premium-planheading">
							Design e-learning interactions for free or subscribe
							to iglooo Premium.
						</h5>
					</Col>
				</Row>

				<Row className="igloo-premium-plans">
					<Col
						md={{ span: 5, offset: 1 }}
						lg={{ span: 3, offset: 3 }}
						xs={{ span: 10, offset: 1 }}
						className="igloo-premium-plan"
					>
						<div>
							<div className="card w-100 text-left">
								<div className="card-body">
									<h5 className="card-title font-weight-bold">
										iglooo Free
									</h5>
									<h6 className="card-price">
										<span className="font-weight-bold igloo-premium-price">
											$0
										</span>
										<span className="period">/month</span>
									</h6>
									<p className="igloo-premium-plansub">
										&nbsp;
									</p>
									<hr />
									<ul className="fa-ul">
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											20 interactions
										</li>
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											100 MB file storage
										</li>
										<li className="text-muted">
											<span className="fa-li">
												<i className="fas fa-times" />
											</span>
											Unlimited interactions
										</li>
										<li className="text-muted">
											<span className="fa-li">
												<i className="fas fa-times" />
											</span>
											Exclusive interactions
										</li>
										<li className="text-muted">
											<span className="fa-li">
												<i className="fas fa-times" />
											</span>
											Team Accounts
										</li>
										<li className="text-muted">
											<span className="fa-li">
												<i className="fas fa-times" />
											</span>
											No advertising
										</li>
									</ul>
									<hr />
									<div className="text-center">
										<Link
											className="btn-getfree btn-block text-uppercase"
											to="/register"
										>
											Get Free
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col
						lg={3}
						md={{ span: 5, offset: 0 }}
						xs={{ span: 10, offset: 1 }}
						className="igloo-premium-plan"
					>
						<div>
							<div className="card w-100">
								<div className="card-body">
									<h5 className="card-title font-weight-bold">
										iglooo Premium
									</h5>
									<h6 className="card-price">
										<span className="font-weight-bold igloo-premium-price">
											$9.99
										</span>
										<span className="period">/month</span>
									</h6>
									<p className="igloo-premium-plansub">
										Start your 30 day free trail*
									</p>
									<hr />
									<ul className="fa-ul">
										<li className="text-muted">
											<span className="fa-li">
												<i className="fas fa-times" />
											</span>
											20 interactions
										</li>
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											10 GB file storage
										</li>
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											Unlimited interactions
										</li>
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											Exclusive interactions
										</li>
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											Team Accounts
										</li>
										<li className="font-weight-bold">
											<span className="fa-li">
												<i className="fas fa-check" />
											</span>
											No advertising
										</li>
									</ul>
									<hr />
									<div className="text-center">
										<Link
											to={
												userContext.user
													? "/subscribe"
													: "/register?premium=true"
											}
											className="btn-getpremium btn-block text-uppercase"
										>
											Get Started
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<Footer />
		</div>
	);
};

export default Premium;
