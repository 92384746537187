import React, { useState } from "react";
import Header from "../ui/Header";
import Loading from "../ui/Loading";

import { Row, Col, Container } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import SubscriptionStore from "./SubscriptionStore";

import "./SubscriptionPage.scss";
import SubscribeForm from "./SubscribeForm";
import { useUsers } from "../auth/UserContext";
import UnsubscribeButton from "./UnsubscribeButton";

const SubscriptionPage = () => {
	const [subscriptionData, setSubscriptionData] = useState();
	const subscriptionStore = new SubscriptionStore();

	const userContext = useUsers();

	const loadSubscription = () => {
		subscriptionStore.loadSubscriptionData().then((response) => {
			setSubscriptionData({
				isSubscribed: response.isSubscribed,
				isDeclined: response.isDeclined,
				subscriptionItem:
					!response.isDeclined && response.isSubscribed
						? JSON.parse(response.subscriptionItem)
						: null,
				reccuringItem:
					!response.isDeclined && response.isSubscribed
						? JSON.parse(response.reccuringItem)
						: null,
			});
		});
	};

	if (!subscriptionData) {
		loadSubscription();
	}

	const handleSubscribed = () => {
		loadSubscription();
	};

	if (!subscriptionData) {
		return <Loading />;
	}

	const {
		subscriptionItem,
		reccuringItem,
		isSubscribed,
		account_info,
		isDeclined,
	} = subscriptionData;

	if (!userContext.user || !userContext.user.isAdmin) {
		return <Header />;
	}

	return (
		<div id="igloo-subscription">
			<Header />
			<div className="igloo-details offset-3">
				{isSubscribed && !isDeclined ? (
					<div className="mt-3">
						<h3 className="mb-5">Subscription Details</h3>
						<Container>
							<Row>
								<Col className="border igloo-th font-weight-bold pt-2 pb-2">
									Plan
								</Col>
								<Col className="border">
									{subscriptionItem.item}
								</Col>
							</Row>
							<Row>
								<Col className="border igloo-th font-weight-bold pt-2 pb-2">
									Recurring Amount
								</Col>
								<Col className="border">
									<CurrencyFormat
										value={
											parseFloat(subscriptionItem.price) +
											parseFloat(subscriptionItem.tax)
										}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									/>{" "}
									/{reccuringItem.interval}
								</Col>
							</Row>
							<Row>
								<Col className="border igloo-th font-weight-bold pt-2 pb-2">
									Recurring On
								</Col>
								<Col className="border">
									{reccuringItem.trigger}
									<sup>th</sup> of Every{" "}
									{reccuringItem.interval}
								</Col>
							</Row>
							<Row>
								<Col className="border igloo-th font-weight-bold pt-2 pb-2">
									Start Date
								</Col>
								<Col className="border">
									{reccuringItem.start_date}
								</Col>
							</Row>
							<Row>
								<Col>
									<Row>
										<Col className="border igloo-th font-weight-bold pt-2 pb-2 text-c">
											Manage Subscription
										</Col>
										<Col className="border font-weight-bold pt-1 pb-1">
											<UnsubscribeButton
												onUnsubscribe={() => {
													loadSubscription();
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</div>
				) : (
					""
				)}
				{!isSubscribed || isDeclined ? (
					<SubscribeForm
						values={account_info}
						onSubscribed={handleSubscribed}
					/>
				) : null}
			</div>
		</div>
	);
};

export default SubscriptionPage;
