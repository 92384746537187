class commonStore {
	getToken = () => {
		return window.localStorage.getItem("auth_token");
	};

	setToken = (token) => {
		if (token) {
			window.localStorage.setItem("auth_token", token);
		} else {
			window.localStorage.removeItem("auth_token");
		}
	};
}

export default new commonStore();
