import { ApiClient } from "../util/ApiClient.js";

class TagStore {
	loadMyTags = async () => {
		const response = await ApiClient().get("tags/myTags/");
		const data = await response.data;

		return data;
	};

	loadPublicTags = async () => {
		const response = await ApiClient().get("tags/publicTags/");
		const data = await response.data;

		return data;
	};
}

export default TagStore;
