import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Alert } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import CategoryDropdown from "../ui/CategoryDropdown.js";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import Search from "../ui/Search.js";
import Loading from "../ui/Loading.js";
import { ApiClient } from "../util/ApiClient.js";
import "./CreateInteraction.scss";
import InteractionListItem from "./InteractionListItem";

import InteractionStore from "./InteractionStore";
import { useUsers } from "../auth/UserContext";

const CreateInteraction = () => {
	const [interactions, setInteractions] = useState();
	const [searchTerm, setSearchTerm] = useState("");
	const [category, setCategory] = useState();
	const [redirect, setRedirect] = useState();

	const userContext = useUsers();

	useEffect(() => {
		document.title = "iglooo - My Dashboard";
	}, []);

	useEffect(() => {
		const interactionStore = new InteractionStore();
		interactionStore.loadDefault(searchTerm, category).then((response) => {
			setInteractions(response.interactions);
		});
	}, [searchTerm, category]);

	const search = (term) => {
		setSearchTerm(term);
	};

	const filterCategory = (category) => {
		setCategory(category);
	};

	const createInteraction = (id) => {
		ApiClient()
			.post(`/interactions/${id}/clone/`)
			.then((response) => {
				setRedirect(response.data.id);
			});
	};

	if (redirect) {
		return <Redirect to={"/interactions/" + redirect + "/edit"} />;
	}

	let result = (
		<div className="common-loading">
			<Loading /> <span>Loading available interactions...</span>
		</div>
	);

	const user = userContext.user;
	if (!user) {
		return result;
	}
	let subscription = user.organization.subscription;

	if (interactions !== undefined) {
		if (interactions.length > 0) {
			result = interactions.map((interaction, idx) => {
				return (
					<InteractionListItem interaction={interaction} key={idx}>
						<div className="button-group">
							<Link
								to={
									"/interactions/" +
									interaction.hashid +
									"/preview"
								}
								className="btn btn-secondary btn-rounded"
							>
								PREVIEW{" "}
							</Link>
							{!interaction.is_premium ||
							subscription.premiumInteractions ? (
								<Button
									className="btn-rounded"
									variant="success"
									onClick={() =>
										createInteraction(interaction.id)
									}
								>
									CREATE
								</Button>
							) : (
								<Link
									to="/subscribe"
									className="btn btn-primary ml-3 btn-rounded"
									variant="success"
								>
									PREMIUM
								</Link>
							)}
						</div>
					</InteractionListItem>
				);
			});
		} else {
			result = (
				<div className="text-center no-interactions">
					<p>No interactions were found.</p>
				</div>
			);
		}
	}

	let limitNotice;

	if (subscription.interactionLimit) {
		if (!subscription.premiumInteractions) {
			limitNotice = (
				<Row>
					<Alert variant="info" style={{ width: "100%" }}>
						With the <strong>{subscription.name}</strong>{" "}
						subscription you are able to create{" "}
						<strong>{subscription.interactionLimit}</strong>{" "}
						interactions. You have created a total of{" "}
						<strong>{user.organization.interactionsCreated}</strong>{" "}
						interactions.
					</Alert>
				</Row>
			);
		}
	}

	return (
		<div className="page-create-interaction">
			<Header pageTitle="Create Interaction">
				<Row
					className="header-rounded-inputs"
					style={{ width: "100%" }}
				>
					<Col md={{ span: 10, offset: 2 }}>
						<Search
							action={search}
							placeholder="Search for an interaction..."
						/>
					</Col>
				</Row>
			</Header>
			<Container>
				<div className="create-interaction-container">
					<h1 className="blue-text">Select Interaction Type</h1>
					<Row className="mb-4">
						<Col md={6}>{limitNotice}</Col>

						<Col
							md={{ span: 5, offset: 1 }}
							className="header-rounded-inputs pb-3 display-flex flex-end"
						>
							<CategoryDropdown action={filterCategory} />
						</Col>
					</Row>

					{result}
				</div>
			</Container>
			<Footer />
		</div>
	);
};

export default CreateInteraction;
