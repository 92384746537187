import React, { useEffect } from "react";
import "./Privacy.scss";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import withSnapEngage from "../util/SnapEngage";
import { Container } from "react-bootstrap";

const Privacy = () => {
	useEffect(() => {
		document.title = "iglooo - Privacy Policy";
	}, []);

	return (
		<div id="page-privacy">
			<Header />
			<Container>
				<h1>Privacy Policy</h1>
				<p>
					If you require any more information or have any questions
					about our privacy policy, please feel free to contact us by
					email at iglooo Team.
				</p>
				<p>
					At https://iglooo.io we consider the privacy of our visitors
					to be extremely important. This privacy policy document
					describes in detail the types of personal information is
					collected and recorded by https://iglooo.io and how we use
					it.
				</p>
				<h3>LOG FILES</h3>
				<p>
					Like many other Web sites, https://iglooo.io makes use of
					log files. These files merely logs visitors to the site -
					usually a standard procedure for hosting companies and a
					part of hosting services's analytics. The information inside
					the log files includes internet protocol (IP) addresses,
					browser type, Internet Service Provider (ISP), date/time
					stamp, referring/exit pages, and possibly the number of
					clicks. This information is used to analyze trends,
					administer the site, track user's movement around the site,
					and gather demographic information. IP addresses, and other
					such information are not linked to any information that is
					personally identifiable.
				</p>
				<h3>COOKIES AND WEB BEACONS</h3>
				<p>
					https://iglooo.io uses cookies to store information about
					visitors' preferences, to record user-specific information
					on which pages the site visitor accesses or visits, and to
					personalize or customize our web page content based upon
					visitors' browser type or other information that the visitor
					sends via their browser.
				</p>
				<h3>DOUBLECLICK DART COOKIE</h3>
				<p>
					- Google, as a third party vendor, uses cookies to serve ads
					on https://iglooo.io.
					<br />
					- Google's use of the DART cookie enables it to serve ads to
					our site's visitors based upon their visit to
					https://iglooo.io and other sites on the Internet.
					<br />
					- Users may opt out of the use of the DART cookie by
					visiting the Google ad and content network privacy policy at
					the following URL - http://www.google.com/privacy_ads.html
					<br />
				</p>
				<h3>OUR ADVERTISING PARTNERS</h3>
				<p>
					Some of our advertising partners may use cookies and web
					beacons on our site. Our advertising partners include Google
					<br />
					You may consult this listing to find the privacy policy for
					each of the advertising partners of https://iglooo.io.
					<br />
					These third-party ad servers or ad networks use technology
					in their respective advertisements and links that appear on
					https://iglooo.io and which are sent directly to your
					browser. They automatically receive your IP address when
					this occurs. Other technologies (such as cookies,
					JavaScript, or Web Beacons) may also be used by our site's
					third-party ad networks to measure the effectiveness of
					their advertising campaigns and/or to personalize the
					advertising content that you see on the site.
				</p>
				<p>
					https://iglooo.io has no access to or control over these
					cookies that are used by third-party advertisers.
				</p>
				<h3>THIRD PARTY PRIVACY POLICIES</h3>
				<p>
					You should consult the respective privacy policies of these
					third-party ad servers for more detailed information on
					their practices as well as for instructions about how to
					opt-out of certain practices. https://iglooo.io's privacy
					policy does not apply to, and we cannot control the
					activities of, such other advertisers or web sites.
				</p>
				<p>
					If you wish to disable cookies, you may do so through your
					individual browser options.
				</p>
				<h3>CHILDREN'S INFORMATION</h3>
				<p>
					We believe it is important to provide added protection for
					children online. We encourage parents and guardians to spend
					time online with their children to observe, participate in
					and/or monitor and guide their online activity.
					https://iglooo.io does not knowingly collect any personally
					identifiable information from children under the age of 13.
					If a parent or guardian believes that https://iglooo.io has
					in its database the personally-identifiable information of a
					child under the age of 13, please contact us immediately
					(using the contact in the first paragraph) and we will use
					our best efforts to promptly remove such information from
					our records.
				</p>
				<h3>ONLINE PRIVACY POLICY ONLY</h3>
				<p>
					This privacy policy applies only to our online activities
					and is valid for visitors to our website and regarding
					information shared and/or collected there. This policy does
					not apply to any information collected offline or via
					channels other than this website.
				</p>
				<h3>CONSENT</h3>
				<p>
					By using our website, you hereby consent to our privacy
					policy and agree to its terms.
				</p>
				<h3>UPDATE</h3>
				<p>
					This Privacy Policy was last updated on: Friday, February
					20th, 2019.
				</p>
				<p>
					<i>
						Should we update, amend or make any changes to our
						privacy policy, those changes will be posted here.
					</i>
				</p>
			</Container>
			<Footer />
		</div>
	);
};

export default withSnapEngage(Privacy);
