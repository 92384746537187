import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Container,
	InputGroup,
	FormControl,
	Button,
} from "react-bootstrap";
import _ from "lodash";
import { useUsers } from "../auth/UserContext";
import Loading from "../ui/Loading";

const UserForm = () => {
	const [changed, setChanged] = useState();

	const userContext = useUsers();

	useEffect(() => {
		if (userContext.user) {
			setChanged(userContext.user.copy());
		}
	}, [userContext.user]);

	const handleChange = (e) => {
		let _changed = { ...changed };

		const target = e.target;
		const value =
			target.type === "checkbox" ? target.checked : target.value;

		_.set(_changed, target.name, value);

		setChanged(_changed);
	};

	const save = () => {
		userContext.saveUser(changed);
	};

	if (!changed) {
		return <Loading />;
	}

	return (
		<Container className="common-wrapper">
			<Row className="frow">
				<Col md={6}>
					<label htmlFor="firstname">First Name</label>
					<InputGroup className="mb-3">
						<FormControl
							id="firstname"
							aria-describedby="firstname"
							value={changed.firstname}
							onChange={handleChange}
							name="firstname"
						/>
					</InputGroup>
				</Col>
				<Col md={6}>
					<label htmlFor="lastname">Last Name</label>
					<InputGroup className="mb-3">
						<FormControl
							id="lastname"
							aria-describedby="lastname"
							value={changed.lastname}
							onChange={handleChange}
							name="lastname"
						/>
					</InputGroup>
				</Col>
				<Col md={6}>
					<label htmlFor="email">Email Address</label>
					<InputGroup className="mb-3">
						<FormControl
							id="email"
							aria-describedby="email"
							value={changed.email}
							onChange={handleChange}
							name="email"
						/>
					</InputGroup>
				</Col>
				<Col md={6}>
					<label htmlFor="password">Password</label>
					<InputGroup className="mb-3">
						<FormControl
							type="password"
							id="password"
							aria-describedby="password"
							value={changed.password}
							onChange={handleChange}
							name="password"
						/>
					</InputGroup>
				</Col>

				<Col md={12}>
					<Button onClick={save} className="mt-3" size="lg">
						Save
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default UserForm;
