import React, { useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import CreditCardInput from "react-credit-card-input";
import { TAXES, TOTALPRICE } from "./SubscriptionConstants";
import SubscriptionStore from "./SubscriptionStore";
import CurrencyFormat from "react-currency-format";

const SubscribeForm = (props) => {
	const [errors, setErrors] = useState({});
	const [values, setValues] = useState({ tax: 0 });

	const subscriptionStore = new SubscriptionStore();

	const onChange = (field, value) => {
		const newValues = { ...values };

		if (field === "exp_date") {
			const cardDates = value.split("/");
			if (cardDates.length === 2) {
				newValues["exp_year"] = cardDates[1].trim();
				newValues["exp_month"] = cardDates[0].trim();
			}
		} else {
			newValues[field] = value;
		}

		if (field === "province") {
			newValues["tax"] = calculateTax(value);
		}

		setValues(newValues);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		subscriptionStore
			.subscribe(values)
			.then(() => {
				props.onSubscribed();
			})
			.catch((error) => {
				setErrors(error.response.data);
			});
	};

	const calculateTax = (province) => {
		return TAXES[province] !== undefined
			? (TAXES[province] * TOTALPRICE) / 100
			: 0;
	};

	return (
		<div>
			<h2 className="mb-4">Subscribe to iglooo Premium</h2>
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col>
						<Form.Group controlId="name_on_card">
							<Form.Label>Name on card</Form.Label>
							<Form.Control
								required
								type="text"
								value={values.name_on_card}
								isInvalid={errors.name_on_card}
								onChange={(event) =>
									onChange("name_on_card", event.target.value)
								}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.name_on_card : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<Form.Group controlId="address1">
							<Form.Label>Address 1</Form.Label>
							<Form.Control
								required
								type="text"
								value={values.address1}
								isInvalid={errors.address1}
								onChange={(event) =>
									onChange("address1", event.target.value)
								}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.address1 : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md="6">
						<Form.Group controlId="address2">
							<Form.Label>Address 2</Form.Label>
							<Form.Control
								type="text"
								value={values.address2}
								isInvalid={errors.address2}
								onChange={(event) =>
									onChange("address2", event.target.value)
								}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.address2 : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<Form.Group controlId="country">
							<Form.Label>Country</Form.Label>
							<CountryDropdown
								required="True"
								value={values.country}
								className="form-control"
								onChange={(value) => onChange("country", value)}
								isInvalid={errors.country}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.country : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md="6">
						<Form.Group controlId="phone">
							<Form.Label>Phone number</Form.Label>
							<Form.Control
								required
								type="text"
								value={values.phone}
								isInvalid={errors.phone}
								onChange={(event) =>
									onChange("phone", event.target.value)
								}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.phone : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md="4">
						<Form.Group controlId="city">
							<Form.Label>City</Form.Label>
							<Form.Control
								required
								type="text"
								value={values.city}
								isInvalid={errors.city}
								onChange={(event) =>
									onChange("city", event.target.value)
								}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.city : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md="4">
						<Form.Group controlId="province">
							<Form.Label>State / Province</Form.Label>
							<RegionDropdown
								value={values.province}
								country={values.country}
								className="form-control"
								onChange={(value) =>
									onChange("province", value)
								}
								isInvalid={errors.province}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.province : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md="4">
						<Form.Group controlId="postal_code">
							<Form.Label>Zip / Postal code</Form.Label>
							<Form.Control
								required
								type="text"
								value={values.postal_code}
								isInvalid={errors.postal_code}
								onChange={(event) =>
									onChange("postal_code", event.target.value)
								}
							/>
							<Form.Control.Feedback type="invalid">
								{errors ? errors.postal_code : ""}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group controlId="card_number">
							<CreditCardInput
								containerClassName="igloo-card"
								fieldClassName="igloo-card-field"
								inputClassName="form-control"
								cardNumberInputProps={{
									id: "card_number",
									value: values.card_number,
									onChange: (event) => {
										onChange(
											"card_number",
											event.target.value,
										);
									},
								}}
								cardExpiryInputProps={{
									id: "exp_date",
									value: values.exp_date,
									onChange: (event) => {
										onChange(
											"exp_date",
											event.target.value,
										);
									},
								}}
								cardCVCInputProps={{
									id: "cvc",
									value: values.cvc,
									onChange: (event) => {
										onChange("cvc", event.target.value);
									},
								}}
								customTextLabels={{
									invalidCardNumber: errors.card_number,
									expiryError: errors.exp_date,
									invalidCvc: errors.cvc,
								}}
							/>
						</Form.Group>
					</Col>
				</Row>
				<div>
					<table className="table table-bordered">
						<tbody>
							<tr>
								<td>
									<strong>Subtotal</strong>
								</td>
								<td>
									<CurrencyFormat
										value={TOTALPRICE}
										decimalScale={2}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									/>
								</td>
							</tr>
							{values.tax ? (
								<tr>
									<td>
										<strong>Tax</strong>
									</td>
									<td>
										<CurrencyFormat
											value={values.tax}
											decimalScale={2}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"$"}
										/>
									</td>
								</tr>
							) : null}
							<tr>
								<td>
									<strong>Total (prices are in USD)</strong>
								</td>
								<td>
									<CurrencyFormat
										value={TOTALPRICE + values.tax}
										decimalScale={2}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									/>
								</td>
							</tr>
						</tbody>
					</table>

					<p className="mt-3">
						Yes, I would like to subscribe to iglooo for&nbsp;
						<CurrencyFormat
							value={TOTALPRICE + values.tax}
							decimalScale={2}
							displayType={"text"}
							thousandSeparator={true}
							prefix={"$"}
						/>
						&nbsp;I know that I can cancel any time.
					</p>
				</div>
				{errors.error ? (
					<Alert variant="danger"> {errors.error} </Alert>
				) : null}
				<Button variant="success" type="submit">
					Subscribe
				</Button>
			</Form>
		</div>
	);
};

export default SubscribeForm;
