import {
	object,
	alias,
	deserialize,
	identifier,
	serializable,
	getDefaultModelSchema,
} from "serializr";
import Organization from "../organization/Organization";

export class User {
	static fromJson(json, prop) {
		if (prop) {
			json = JSON.parse(json)[prop];
		}

		return deserialize(this, json);
	}

	@serializable(identifier())
	id;

	@serializable
	email;

	@serializable(alias("first_name"))
	firstname;

	@serializable(alias("last_name"))
	lastname;

	@serializable(alias("is_admin"))
	isAdmin;

	@serializable(alias("is_active"))
	isActive;

	@serializable
	password;

	@serializable
	organization;

	constructor(props) {
		if (!props) {
			props = {};
		}

		this.id = props.id;
		this.firstname = props.firstname;
		this.lastname = props.lastname;
		this.email = props.email;
		this.isActive = props.isActive;
		this.isAdmin = props.isAdmin;
		this.organization = props.organization;
		this.password = props.password;

		getDefaultModelSchema(User).props.organization = object(Organization);
	}

	copy() {
		return new User(this);
	}
}
