import React from "react";
import MediaItem from "./MediaItem";
import { Row } from "react-bootstrap";
import { useMedia } from "./MediaContext";

const MediaList = () => {
	const mediaContext = useMedia();

	if (!mediaContext.media) {
		mediaContext.getMedia();
		return <div>loading...</div>;
	}

	let mediaItems = mediaContext.media.map((item, idx) => {
		return <MediaItem item={item} key={idx} />;
	});

	return <Row className="media-list">{mediaItems}</Row>;
};

export default MediaList;
