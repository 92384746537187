import { ApiClient } from "../util/ApiClient.js";
import { INTERACTION_PAGE_SIZE } from "../config/Settings";

class InteractionStore {
	loadInteractions = async (interactionType, search, category, tag, page) => {
		const params = {};
		let type = "";

		if (search) {
			params["search"] = search;
		}

		if (page != null) {
			params["offset"] = (page - 1) * INTERACTION_PAGE_SIZE;
			params["limit"] = INTERACTION_PAGE_SIZE;
		}

		if (category) {
			params["category"] = category;
		}

		if (tag) {
			params["tag"] = tag;
		}

		if (interactionType != null) {
			type = interactionType + "/";
		}

		const response = await ApiClient().get("interactions/" + type, {
			params,
		});

		const data = await response.data;
		const interactionCount = await response.headers["interaction-count"];

		return {
			interactions: data,
			interactionCount: interactionCount,
		};
	};

	loadDefault = (search = null, category = null, tag = null, page = null) => {
		return this.loadInteractions("default", search, category, tag, page);
	};

	loadPublic = (search = null, category = null, tag = null, page = null) => {
		return this.loadInteractions("public", search, category, tag, page);
	};

	loadMyInteractions = (
		search = null,
		category = null,
		tag = null,
		page = null,
	) => {
		return this.loadInteractions(null, search, category, tag, page);
	};
}

export default InteractionStore;
