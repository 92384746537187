import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../ui/Header.js";
import _ from "lodash";
import Footer from "../ui/Footer";
import InteractionStore from "../interaction/InteractionStore";
import { Link, useHistory } from "react-router-dom";

import "./Library.scss";
import Loading from "../ui/Loading.js";

const Library = () => {
	const [interactions, setInteractions] = useState();

	const interactionStore = new InteractionStore();
	const history = useHistory();

	const loadinteractions = () => {
		interactionStore.loadDefault(null, null).then((response) => {
			setInteractions(response.interactions);
		});
	};

	if (!interactions) {
		loadinteractions();
		return <Loading />;
	}

	const getColumnsGrouped = (startIndex, endIndex, interactionColumns) => {
		let columnsGroup = [];

		for (
			let i = startIndex;
			i < interactionColumns.length && i < endIndex;
			i++
		) {
			columnsGroup.push(interactionColumns[i]);
		}
		return columnsGroup;
	};

	const getPeview = (hashid) => {
		let url = "/interactions/" + hashid + "/preview";
		history.push(url);
	};

	const getDefaultInteractionItems = () => {
		let interactionItems = [];
		let interactionColumns = [];
		let columnOffset = "";

		_.each(interactions, (item, idx) => {
			if (idx % 4 === 0) columnOffset = "offset-md-2";
			else columnOffset = "";

			interactionColumns.push(
				<Col
					onClick={() => getPeview(item.hashid)}
					className={columnOffset}
					md={{ span: 2, columnOffset }}
					sm={6}
				>
					<div style={{ height: 200 }}>
						<img
							className="card-img-top"
							src={item.icon}
							style={{ maxHeight: "200px", maxWidth: "200px" }}
							alt="Interaction Icon"
						/>
					</div>
					<div className="igloo-lib-dflt-name">{item.name}</div>
					<span className="igloo-lib-dflt-description">
						{item.description}
					</span>
				</Col>,
			);
		});

		for (let i = 0; i <= interactions.length / 4 + 1; i++) {
			interactionItems.push(
				<Row className="igloo-lib-dflt-interactionrow">
					{getColumnsGrouped(i * 4, (i + 1) * 4, interactionColumns)}
				</Row>,
			);
		}

		return interactionItems;
	};

	return (
		<div id="library-page">
			<Header>
				<Row style={{ width: "100%" }}>
					<Col className="vertical-align igloo-login-nav">
						<Link to="/login" className="btn btn-rounded">
							Log in
						</Link>
						<Link to="/register" className="btn btn-rounded active">
							Sign up
						</Link>
					</Col>
				</Row>
			</Header>

			<div className="igloo-lib-dflt-Interactions text-center">
				<h1 className="font-weight-bold">Interaction Library</h1>
				<Row>
					<Col sm="12" md={{ size: 7, offset: 3 }} lg="6">
						<h5 className="text-muted text-center">
							iglooo makes it easy to create a wide range of
							interactivities for your eLearning needs. Preview
							and explore what’s available below.
						</h5>
					</Col>
				</Row>
				<div className="igloo-lib-dflt-interaction">
					{getDefaultInteractionItems()}
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Library;
