import React, { useEffect } from "react";
import StatsMap from "./StatsMap";

import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";

import "./Stats.scss";

const Stats = () => {
	useEffect(() => {
		document.title = "iglooo - Stats";
	});

	return (
		<div id="page-stats">
			<Header pageTitle="iglooo Stats" />
			<StatsMap />
			<Footer />
		</div>
	);
};

export default Stats;
