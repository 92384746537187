export const TAXES = {
	Alberta: 5,
	"British Columbia": 5,
	Manitoba: 5,
	"New Brunswick": 15,
	"Newfoundland and Labrador": 15,
	"Nova Scotia": 15,
	Ontario: 13,
	"Prince Edward Island": 15,
	Quebec: 5,
	Saskatchewan: 5,
	"Northwest Territories": 5,
	Nunavut: 5,
	Yukon: 5,
};
export const TOTALPRICE = 9.99;
