import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import "./login.scss";
import withSnapEngage from "../util/SnapEngage";
import { URL, FORGOT_PASSWORD_URL } from "../config/Api";

const ForgotPassword = () => {
	const [email, setEmail] = useState();
	const [emailSent, setEmailSent] = useState(false);

	useEffect(() => {
		document.title = "iglooo - Login";
	}, []);

	const handleEmailChange = (e) => setEmail(e.target.value);

	const handleSubmit = (event) => {
		event.preventDefault();
		return axios
			.post(URL + FORGOT_PASSWORD_URL, {
				email: email,
			})
			.then(() => {
				setEmailSent(true);
			});
	};

	return (
		<div className="page-login">
			<Header>
				<Row style={{ width: "100%" }}>
					<Col className="vertical-align igloo-login-nav">
						<Link to="/login" className="btn btn-rounded">
							Log in
						</Link>
						<Link to="/register" className="btn btn-rounded active">
							Sign up
						</Link>
					</Col>
				</Row>
			</Header>
			<div className="login-container container-fluid">
				<div className="frow row">
					{emailSent ? (
						<>
							<p>
								We've emailed you instructions for setting your
								password, if an account exists with the email
								you entered. You should receive them shortly.
							</p>
							<p>
								If you don't receive an email, please make sure
								you've entered the address you registered with,
								and check your spam folder.
							</p>
						</>
					) : (
						<div className="col-sm">
							<h1> Request a new password </h1>
							<form
								onSubmit={handleSubmit}
								method="POST"
								id="signup-form"
							>
								<Form.Group controlId="username">
									<Form.Label>Email</Form.Label>
									<Form.Control
										required
										type="text"
										onChange={handleEmailChange}
									/>
								</Form.Group>

								<div className="button">
									<button className="common-gradient">
										<span>Request Reset</span>
									</button>
								</div>
							</form>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default withSnapEngage(ForgotPassword);
