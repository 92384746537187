import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { debounce } from "throttle-debounce";
import "./Search.scss";

/**
 * Simple Search input component. A prop named action is required to be passed in,
 * this prop will receive the search term when the input is filled out.
 */
const Search = (props) => {
	const callAction = debounce(500, props.action);

	const handleSearch = (e) => {
		e.persist();
		callAction(e.target.value);
	};

	return (
		<FormGroup size="large" className="form-search">
			<InputGroup>
				<InputGroup.Prepend className="vertical-align">
					<FontAwesomeIcon icon={faSearch} />
				</InputGroup.Prepend>
				<FormControl
					type="text"
					className="search-input"
					placeholder={props.placeholder || "Search..."}
					onKeyUp={handleSearch}
				/>
			</InputGroup>
		</FormGroup>
	);
};

export default Search;
