import React from "react";
import { Card } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import filesize from "filesize";
import { useUsers } from "../auth/UserContext";
import { useMedia } from "./MediaContext";

import "./MediaItem.scss";

const MediaItem = (props) => {
	const mediaContext = useMedia();
	const userContext = useUsers();

	const handleDelete = () => {
		const item = props.item;
		confirmAlert({
			title: "Confirm to delete",
			message:
				"Are you sure you want to delete: " +
				item.filename +
				"? this action cannot be undone.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						mediaContext.deleteMedia(item.id).then(() => {
							userContext.getUser();
						});
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const item = props.item;

	return (
		<Card className="media-item">
			<div className="card-thumbnail">
				<img src={item.url} alt={item.filename} />
			</div>
			<Card.Body>
				<Card.Title className="truncate">{item.filename}</Card.Title>
			</Card.Body>
			<Card.Footer>
				{filesize(item.size)}
				<span className="float-right" onClick={handleDelete}>
					Delete
				</span>
			</Card.Footer>
		</Card>
	);
};

export default MediaItem;
