import React from "react";

export default function IglooLogo(props) {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 93.7 53"
			style={{ enableBackground: "new 0 0 93.7 53" }}
			xmlSpace="preserve"
			width={props.width}
		>
			<path
				className="st0"
				fill={props.fill}
				d="M90.7,45.4c-1-0.1-2.2-0.3-3.5-0.4c-1.4-0.2-2.9-0.2-4.6-0.3c-0.3,0-0.7,0-1.1,0c0.7-2.5,1.6-6.8,1.3-10.4
			C81.5,14.8,66.3,0,47.5,0c-13.7,0-26.1,7.7-32,19.7C13,19.8,11,19.9,10,20.1c0,0,0,0,0,0c-0.2,0-0.3,0-0.4,0c-1.9,0.2-3.7,1-5.1,2.4
			C0,27,0,36.2,0,36.6l0.3,8.6c0,0.5,0.3,0.9,0.8,1.1l5,1.8c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0,0c0.1,0.1,0.3,0.1,0.4,0.1
			c0.1,0,0.1,0,0.2,0l8.6-1.4l0.1,3.9c0,0.6,0.4,1.1,1,1.1l5.8,0.9c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.4-0.1l11.6-3.6
			c0.5,0.1,0.9,0.2,1.4,0.3c0.9,0.2,1.8,0.3,2.8,0.5c1.9,0.3,3.9,0.6,6,0.8c2.1,0.3,4.3,0.4,6.5,0.5c2.2,0.1,4.5,0,6.8-0.3
			c0.6-0.1,1.2-0.2,1.7-0.3l1.7-0.4c1.1-0.2,2.2-0.5,3.3-0.8c2.1-0.6,4.2-1.1,6.2-1.6c2-0.5,4-0.9,5.8-1.2c1.9-0.3,3.6-0.5,5.3-0.6
			c1.7-0.1,3.2-0.2,4.5-0.2c1.4-0.1,2.5,0,3.5-0.1c2,0,3.1,0,3.1,0S92.6,45.8,90.7,45.4z M63.1,39.4l-0.5-7.7c0,0,0,0,0,0
			c2.2,0,4.4-0.1,6.6-0.3c1.5-0.1,2.9-0.2,4.4-0.3l1.1,7.5c-1.5,0.2-3,0.3-4.5,0.5C67.7,39.2,65.4,39.3,63.1,39.4z M52.7,39.7
			l-4.6,0.1v-8.1c0,0,0,0,0,0c3.3,0.1,6.5,0.1,9.8,0.1c1.2,0,2.4,0,3.5,0c0,0,0,0.1,0,0.1l0.5,7.7c-1.2,0-2.3,0.1-3.5,0.1L52.7,39.7z
			M36.3,39.9l0-1.5c0-0.6-0.9-13.3-7.7-17.3c3.4,0.2,7.4,0.4,11.6,0.5c0,0,0.1,0,0.1,0l-0.2,9c0,0.1,0,0.2,0,0.2l-4.5,0.1
			c1.9,0.1,3.7,0.2,5.6,0.3c1.9,0.1,3.7,0.2,5.6,0.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0.1v8.1l-0.1,0L36.3,39.9z M3.4,29.2l2.8,3.5
			c-0.5,1-0.9,2.2-1,3.7L2.4,36C2.4,34.9,2.6,32,3.4,29.2z M9.3,22.5c0,0,0.1,0,0.1,0c0,0,0.3,0,0.8-0.1c0.9,0,2,0.1,3.1,0.5
			c0,0,0,0,0.1,0l-1.3,6.3c-0.3-0.1-0.6-0.2-0.9-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0.1-1.1,0.3l-1.5-6.5C8.7,22.6,9,22.5,9.3,22.5z
			M24.8,22.4c3.2,0.3,5.2,2.8,6.6,5.7l-10.3,0.6c-1.5-3.2-3.3-5.2-4.6-6.4c0.2-0.1,0.3-0.2,0.4-0.3C19.2,22,21.8,22.1,24.8,22.4z
			M19,29.9L16,32.6c-0.7-1.2-1.6-2.3-2.8-3l1.2-6C15.5,24.6,17.4,26.5,19,29.9z M17.8,39.2c-0.1-1.3-0.5-3.5-1.3-5.6l3-2.7
			c0.9,2.3,1.7,5.1,2.1,8.7L17.8,39.2z M24,40.7l9.9-1.6l-0.1,4.4l-9.7,2l0-2.1C24.1,42.4,24.1,41.5,24,40.7z M23.9,39.6
			c-0.4-4-1.3-7.2-2.3-9.7l10.3-0.6c1.4,3.5,1.9,7.4,2,8.8L23.9,39.6z M11,31.3c2,0.4,3.1,2.4,3.7,4.4l-7.2,0.6
			C8.1,32.3,10.4,31.5,11,31.3z M2.4,37.2l2.7,0.3c0,0.1,0,0.2,0,0.3c0,1.6,0,3,0,4.1l-2.5-0.4L2.4,37.2z M7.4,37.8c0-0.1,0-0.2,0-0.3
			l7.6-0.7c0.4,1.9,0.5,3.5,0.5,3.5l0,0.7L7.4,42C7.4,40.9,7.4,39.5,7.4,37.8z M17.9,40.4l3.8,0.4c0.1,0.8,0.1,1.7,0.1,2.6l0,2.5
			L18,45.5L17.9,40.4z M24.1,46.6l9.7-2l-0.1,2.7l-9.6,3L24.1,46.6z M35.3,7.6c0.5-0.1,1-0.1,1.6-0.2c1-0.1,2.1-0.2,3.2-0.2
			c0.6,0,1.2,0,1.9-0.1l-0.6,5.3c-1.3,0-2.5,0.1-3.8,0.1c-1.3,0-2.7,0.1-4,0.1L35.3,8C35.4,7.9,35.4,7.8,35.3,7.6z M46.5,2.4l0.2,3.7
			c-1,0-2,0-3,0.1c-1.3,0.1-2.5,0.1-3.6,0.3c-0.1,0-0.1,0-0.2,0L40.7,3C42.6,2.6,44.6,2.4,46.5,2.4z M64.7,13.4c1.1,0,2.3,0,3.4-0.1
			l2.8,7.5c-1.2,0-2.5,0-3.8,0c-2,0-4.1,0-6.2,0l-1.2-7.4l4.5,0l0,0l0,0L64.7,13.4z M79.7,29.2c-1.7,0.4-3.4,0.6-5.1,0.8
			c-1.8,0.2-3.7,0.3-5.5,0.4c0,0,0,0-0.1,0l-1.2-8.6c1.9,0,3.8-0.1,5.6-0.1c1.3,0,2.5-0.1,3.7-0.2C78.3,23.9,79.2,26.5,79.7,29.2z
			M57.9,30.7l-2,0l-0.7-8.9c3.9,0,7.8-0.1,11.5-0.1l1.2,8.6C64.6,30.6,61.3,30.7,57.9,30.7z M41.1,31c0-0.1,0-0.1,0-0.2l0.2-9
			c4,0.1,8.1,0.1,12.3,0.2c0.1,0,0.3,0,0.4,0l0.7,8.9L41.1,31z M43.7,7.2c2.5,0,5.2,0,7.8,0.1c0.1,0,0.1,0,0.2,0l0.6,5.2l-3.9,0
			l-5.4,0c-0.2,0-0.3,0-0.5,0l0.6-5.3C43.3,7.2,43.5,7.2,43.7,7.2z M48.4,13.5l4.5,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0l0.8,0
			l4.8,0l1.2,7.3c-2,0-4.1,0-6.2,0c-1.9,0-3.8,0-5.6,0c0,0,0-0.1,0-0.1l-0.3-7.3L48.4,13.5z M73.4,20.8c-0.4,0-0.9,0-1.3,0l0.1,0
			l-2.8-7.5c0.3,0,0.6,0,0.9,0c0.6,0,1.1,0.1,1.7,0.1c1.9,2.2,3.6,4.7,4.9,7.3C75.7,20.8,74.6,20.8,73.4,20.8z M59.3,12.4l-5.4,0
			l-0.4,0l-0.6-5.1c2.2,0.1,4.4,0.2,6.5,0.3c0.6,0,1.2,0.1,1.9,0.1c0.4,0,0.7,0,1,0l1.6,4.8L59.3,12.4z M63.1,6.9
			c-0.6,0-1.2-0.1-1.8-0.1c-0.6-0.1-1.2-0.1-1.8-0.2c-1.4-0.1-2.9-0.2-4.4-0.3l-0.6-3.2c3.5,0.8,6.8,2.1,9.8,3.9
			C63.8,7,63.5,6.9,63.1,6.9z M53.9,6.3c-0.8,0-1.5-0.1-2.3-0.1c-1.3,0-2.5,0-3.8,0l-0.2-3.8c1.9,0,3.8,0.2,5.6,0.5L53.9,6.3z
			M37.5,13.3c1.8,0,3.6,0.1,5.4,0.1l3.7,0l0.3,7.3c0,0,0,0,0,0c-2.3,0-4.6,0-6.8,0c-1.7,0-3.3,0-4.9-0.1c0-0.1,0.1-0.1,0.1-0.2
			l1.3-7.1c0-0.1,0-0.1,0-0.2C36.9,13.3,37.2,13.3,37.5,13.3z M75.7,38.4L74.6,31c0,0,0.1,0,0.1,0c0.9-0.1,1.9-0.2,2.8-0.4
			c0.8-0.1,1.6-0.3,2.4-0.5c0.3,1.5,0.5,2.9,0.6,4.5c0.1,1,0,2-0.1,3c-0.6,0.2-1.2,0.4-1.9,0.5C77.6,38.2,76.7,38.3,75.7,38.4
			C75.7,38.4,75.7,38.4,75.7,38.4z M71.2,12.7c-0.3,0-0.7-0.1-1-0.1l0,0h0c-1.7,0-3.4-0.1-5.1-0.1l-1.6-4.8c0.7,0,1.3,0,1.9,0
			C67.5,9.2,69.5,10.8,71.2,12.7z M39.5,3.3l-0.8,3.3c0,0,0,0,0,0c-0.7,0.1-1.3,0.2-1.9,0.2c-1,0.2-1.9,0.3-2.7,0.5
			c-0.8,0.2-1.5,0.4-2.1,0.5c-1.1,0.4-1.7,0.6-1.7,0.6s0.7-0.2,1.8-0.4c0.6-0.1,1.3-0.2,2.1-0.3c0,0,0,0,0,0l-1.8,4.6
			c0,0.1,0,0.2,0,0.4c-0.1,0-0.2,0-0.3,0c-1.8,0.1-3.6,0.1-5.4,0.2c1.8,0.1,3.6,0.1,5.4,0.2c1.1,0,2.3,0.1,3.4,0.1l-1.3,7.1
			c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.2,0-0.3,0c-2,0-3.8-0.1-5.6-0.1c-0.4,0-0.9,0-1.3,0c-0.6-0.2-1.3-0.4-2.1-0.5c-0.1,0-0.2,0-0.3,0
			c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.9,0.3-1.3c0.1-0.4,0.2-0.9,0.2-1.4c-0.3,0.4-0.6,0.7-0.8,1.1c-0.2,0.4-0.4,0.8-0.6,1.2
			c-0.2,0.5-0.4,0.9-0.6,1.4c-2-0.2-3.8-0.2-5.5-0.2C22.5,11.4,30.4,5.5,39.5,3.3z M6.2,24.2c0.4-0.4,0.8-0.7,1.2-0.9l1.5,6.5
			c-0.7,0.4-1.4,1.1-2,1.9L3.9,28C4.4,26.5,5.1,25.2,6.2,24.2z M2.6,44.3l-0.1-1.7L5.1,43c0,0.9,0,1.7,0.1,2.2L2.6,44.3z M7.5,45.8
			c0-0.6-0.1-1.5-0.1-2.7l8.2-0.9l0,2.3L7.5,45.8z M18.1,49.8L18,46.6l3.8,0.4l0,3.3L18.1,49.8z M36.7,48.2c-0.2,0-0.4-0.1-0.6-0.1
			l0.2-8.1c1.6,0.1,3.2,0.2,4.8,0.3c0,0.1,0,0.1,0,0.2l0.2,8.1c-0.6-0.1-1.2-0.1-1.8-0.2C38.5,48.4,37.6,48.3,36.7,48.2z M45.4,49
			c-1-0.1-2.1-0.1-3.1-0.2l-0.2-8.2c0,0,0-0.1,0-0.1c1.6,0.1,3.2,0.1,4.8,0.2c1.9,0,3.9,0.1,5.8,0.1l1.8,0l0.3,8.5
			C51.6,49.3,48.4,49.2,45.4,49z M64.7,47.4c-1.1,0.3-2.1,0.5-3.2,0.8l-1.6,0.4c-0.5,0.1-1,0.2-1.6,0.3c-0.8,0.1-1.6,0.2-2.4,0.2
			l-0.3-8.4l2.9,0c3.4,0,6.8-0.2,10.2-0.4l0.5,6.1C67.7,46.6,66.2,47,64.7,47.4z M71,45.9c-0.2,0-0.5,0.1-0.7,0.2l-0.5-6
			c0.1,0,0.2,0,0.3,0c1.9-0.2,3.9-0.4,5.8-0.7c1.5-0.2,3-0.5,4.5-1c-0.3,2.4-0.9,4.8-1.3,6.3c0,0.1,0,0.1,0,0.1
			C76.5,45,73.8,45.3,71,45.9z"
			/>
		</svg>
	);
}
