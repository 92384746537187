import React, { useState, useEffect } from "react";
import { URL } from "../config/Api.js";
import { embedURL } from "../config/Settings";
import Modal from "../ui/Modal.js";
import { Container, Row, Col, Button } from "react-bootstrap";

const UseModal = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [copiedMessage, setCopiedMessage] = useState("");
	let embedCodeNode = null;

	useEffect(() => {
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	const toggleModal = () => {
		props.onClose(!isOpen);
		setIsOpen(!isOpen);
	};

	const copyCode = () => {
		embedCodeNode.select();
		embedCodeNode.setSelectionRange(0, 99999);
		document.execCommand("copy");
		document.getSelection().removeAllRanges();

		setCopiedMessage("Code copied");
		setTimeout(() => setCopiedMessage(""), 2000);
	};

	const { interactionHashid, interactionId } = props;

	const embedCode = `<div class="igloo-interaction-embed" data-interaction-id="${interactionHashid}"></div><script src="${embedURL}" data-interaction-id="${interactionHashid}"></script>`;

	return (
		<Modal show={isOpen} onClose={toggleModal}>
			<button
				type="button"
				className="close"
				aria-label="Close"
				style={{
					backgroundColor: "black",
					zIndex: 1,
					borderRadius: "100%",
					width: 30,
					height: 30,
					marginTop: -20,
					marginRight: -20,
				}}
				onClick={toggleModal}
			>
				<span
					aria-hidden="true"
					style={{
						color: "white",
						fontWeight: "lighter",
					}}
				>
					&times;
				</span>
			</button>
			<Container>
				<Row className="mt-3">
					<Col>
						<h4>Use This Interaction</h4>
						<Button
							type="button"
							variant="success"
							className="btn btn-rounded text-uppercase mt-3"
							onClick={copyCode}
						>
							Copy Code
						</Button>
						<h4>{copiedMessage}</h4>
					</Col>
					<Col>
						<div id="use">
							<p>
								This interaction can be used as a stand-alone
								module, download the SCORM or xAPI / TinCan
								packages to import into your compliant LMS.
							</p>
							<a
								style={{ marginRight: 20 }}
								href={
									URL +
									"/interactions/" +
									interactionId +
									"/export_scorm_12"
								}
								className="btn btn-secondary btn-rounded"
							>
								Download SCORM 1.2 Package
							</a>
							<a
								href={
									URL +
									"/interactions/" +
									interactionId +
									"/export_xapi"
								}
								className="btn btn-secondary btn-rounded"
							>
								Download xAPI / TinCan Package
							</a>
							<a
								href={
									URL +
									"/interactions/" +
									interactionId +
									"/export_storyline"
								}
								className="btn btn-secondary btn-rounded mt-3"
							>
								Download Articulate Storyline Web Object
							</a>
						</div>
					</Col>
				</Row>
			</Container>

			<Container
				id="use"
				className="mt-5"
				style={{ marginTop: 20, marginBottom: 50 }}
			>
				<Row>
					<Col>
						<p>
							This interaction can be embeded into any web-based
							content. Copy the code below and paste it into your
							content. If the interaction is being run in a SCORM
							or xAPI / TinCan compliant LMS than results will
							automatically be reported to the LMS.
						</p>

						<textarea
							id="embed-code"
							ref={(node) => {
								embedCodeNode = node;
							}}
							style={{ borderRadius: 4, resize: "none" }}
							className="col m10 text-center"
							defaultValue={embedCode}
							onClick={() => embedCodeNode.select()}
						/>
					</Col>
				</Row>
			</Container>
		</Modal>
	);
};

export default UseModal;
