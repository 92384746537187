import { User } from "../auth/User";
import { ApiClient } from "../util/ApiClient.js";
import Organization from "./Organization";

class OrganizationStore {
	getCurrent = async () => {
		const response = await ApiClient().get("organization/");
		const data = await response.data;
		return Organization.fromJson(data);
	};

	getCurrentTeam = async () => {
		const response = await ApiClient().get("organization/team/");
		const data = await response.data;
		return data.map((json) => User.fromJson(json));
	};

	invite = async (email) => {
		return ApiClient().post("organization/invite/", {
			email: email,
		});
	};
}

export default OrganizationStore;
