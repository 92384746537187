import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";

import SubscriptionStore from "./SubscriptionStore";

const UnsubscribeButton = (props) => {
	const [inProgress, setInProgress] = useState(false);
	const subscriptionStore = new SubscriptionStore();

	const handleUnsubscribe = () => {
		setInProgress(true);
		confirmAlert({
			title: "Confirm unsubscribe",
			message:
				"Are you sure you want to unsubscribe from iglooo Premium? " +
				"After unsubscribing you will loose access to your team " +
				"accounts and will only be able to access your most recent 20 interactions.",

			buttons: [
				{
					label: "Yes",
					onClick: () => {
						subscriptionStore.unsubscribe().then(() => {
							props.onUnsubscribe();
							setInProgress(false);
						});
					},
				},
				{
					label: "No",
					onClick: () => {
						setInProgress(false);
					},
				},
			],
		});
	};

	return (
		<Button
			block
			variant="primary"
			onClick={handleUnsubscribe}
			disabled={inProgress}
		>
			Unsubscribe
		</Button>
	);
};

export default UnsubscribeButton;
