import React, { useState, useEffect } from "react";
import { bubble as Menu } from "react-burger-menu";
import { Link, useHistory } from "react-router-dom";
import { FREE_SUBSCRIPTION_NAME } from "../config/Settings";
import "./Sidebar.scss";
import { useUsers } from "../auth/UserContext";
import AuthStore from "../auth/AuthStore";

const Sidebar = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const userContext = useUsers();
	const authStore = new AuthStore();
	const history = useHistory();

	useEffect(() => {
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	const stateChange = (state) => {
		setIsOpen(state.isOpen);
		props.onStateChange(state);
	};

	const handleMenuClick = () => {
		setIsOpen(false);
	};

	const handleLogout = () => {
		userContext.forgetUser();
		authStore.logout().then(() => history.replace("/login"));
	};

	let adminLinks = null;
	let isFreeSubscription = true;
	const user = userContext.user;

	if (user && user.isAdmin) {
		adminLinks = <Link to="/team">My Team</Link>;
	}

	if (
		user &&
		user.organization.subscription.name !== FREE_SUBSCRIPTION_NAME
	) {
		isFreeSubscription = false;
	}

	return (
		<Menu
			isOpen={isOpen}
			customBurgerIcon={false}
			width={400}
			onStateChange={stateChange}
		>
			<Link onClick={handleMenuClick} to="/">
				Home
			</Link>
			<Link onClick={handleMenuClick} to="/dashboard">
				Dashboard
			</Link>
			<Link onClick={handleMenuClick} to="/interactions/public">
				Public Interactions
			</Link>
			<Link onClick={handleMenuClick} to="/interactions/create">
				Create
			</Link>
			<Link onClick={handleMenuClick} to="/media">
				Media Library
			</Link>
			<Link onClick={handleMenuClick} to="/profile">
				My Profile
			</Link>
			{user && user.isAdmin ? (
				<Link onClick={handleMenuClick} to="/subscribe">
					{isFreeSubscription
						? "Upgrade to Premium"
						: "My Subscription"}
				</Link>
			) : null}
			{adminLinks}
			<button className="btn btn-link" onClick={handleLogout}>
				Logout
			</button>
		</Menu>
	);
};

export default Sidebar;
