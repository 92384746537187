import { ApiClient } from "../util/ApiClient.js";
import { SUBSCRIPTION, SUBSCRIBE, URL, UNSUBSCRIBE } from "../config/Api";

class SubscriptionStore {
	loadSubscriptionData = async () => {
		const response = await ApiClient().get(
			SUBSCRIPTION + "/get_subscription",
		);
		const data = await response.data;

		return data;
	};

	subscribe = async (values) => {
		return await ApiClient().post(URL + SUBSCRIBE, values);
	};

	unsubscribe = async () => {
		const unsub = await ApiClient().get(UNSUBSCRIBE + "/unsubscribe");
		return unsub;
	};
}

export default SubscriptionStore;
