import React, { useEffect, useState } from "react";
import { Row, Container, Col, Form, Alert } from "react-bootstrap";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import withSnapEngage from "../util/SnapEngage";
import QueryStirng from "query-string";
import "./login.scss";
import AuthStore from "./AuthStore.js";
import { useHistory } from "react-router-dom";
import { useUsers } from "./UserContext.js";

const Register = () => {
	const [errors, setErrors] = useState({});
	const [inProgress, setInProgress] = useState(false);

	const [first_name, setFirstname] = useState("");
	const [last_name, setlastname] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [username, setUsername] = useState("");

	let inviteKey;
	const params = QueryStirng.parse(window.location.search);

	const history = useHistory();
	const authStore = new AuthStore();
	const userContext = useUsers();

	useEffect(() => {
		document.title = "iglooo - Sign Up";
	});

	let isPremium =
		params["premium"] !== undefined ? params["premium"] === "true" : false;
	if (params.key) {
		inviteKey = params.key;
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setInProgress(true);

		authStore
			.register(
				first_name,
				last_name,
				email,
				username,
				password,
				inviteKey,
			)
			.then(
				() => {
					authStore.login(username, password).then(() => {
						userContext.getUser();
						if (isPremium) {
							history.replace("/subscribe");
						} else {
							history.replace("/dashboard");
						}
					});
				},
				(error) => {
					setInProgress(false);
					setErrors(error.response.data);
				},
			);
	};

	return (
		<div className="page-login page-register">
			<Header>
				<Row style={{ width: "100%" }}>
					<Col className="vertical-align igloo-login-nav">
						<a href="/login" className="btn btn-rounded">
							Log in
						</a>
						<a href="/register" className="btn btn-rounded active">
							Sign up
						</a>
					</Col>
				</Row>
			</Header>
			<Container className="login-container" fluid>
				<Row className="frow">
					<div className="col-sm">
						<h1> Create your iglooo Account </h1>
						<div className="message-margin mb-2">
							{errors.error ? (
								<div className="alert alert-danger">
									{errors.error}
								</div>
							) : null}
						</div>
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group controlId="username">
								<Form.Label>Username</Form.Label>
								<Form.Control
									required
									type="text"
									value={username}
									isInvalid={errors.username}
									onChange={(event) =>
										setUsername(event.target.value)
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.username}
								</Form.Control.Feedback>
							</Form.Group>
							<Row>
								<Col md={6}>
									<Form.Group controlId="first_name">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											required
											type="text"
											value={first_name}
											isInvalid={errors.first_name}
											onChange={(event) =>
												setFirstname(event.target.value)
											}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.first_name}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group controlId="last_name">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											required
											type="text"
											value={last_name}
											isInvalid={errors.last_name}
											onChange={(event) =>
												setlastname(event.target.value)
											}
										/>
										<Form.Control.Feedback type="invalid">
											{errors ? errors.last_name : ""}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Form.Group controlId="email">
								<Form.Label>Email</Form.Label>
								<Form.Control
									required
									type="text"
									value={email}
									isInvalid={errors.email}
									onChange={(event) =>
										setEmail(event.target.value)
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors ? errors.email : ""}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="password">
								<Form.Label>Password</Form.Label>
								<Form.Control
									required
									type="password"
									value={password}
									onChange={(event) =>
										setPassword(event.target.value)
									}
								/>
								<Form.Control.Feedback type="invalid">
									{errors ? errors.password : ""}
								</Form.Control.Feedback>
							</Form.Group>
							{errors && errors.message ? (
								<Alert variant="danger">{errors.message}</Alert>
							) : null}
							<div className="button">
								<button
									className="common-gradient"
									disabled={inProgress}
								>
									<span>Sign Up</span>
								</button>
							</div>
						</Form>
					</div>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default withSnapEngage(Register);
