import "./polyfills";
import "igloo-common/src/styles-common.scss";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Login from "./auth/Login";
import Register from "./auth/Register";
import ForgotPassword from "./auth/ForgotPassword";
import PasswordReset from "./auth/PasswordReset";
import Team from "./auth/Team";
import Dashboard from "./dashboard";
import Home from "./home";
import HomeNew from "./home/HomeNew";
import Features from "./features/Features";
import Privacy from "./home/Privacy";
import CreateInteraction from "./interaction/CreateInteraction";
import EditInteraction from "./interaction/EditInteraction";
import PreviewInteraction from "./interaction/PreviewInteraction";
import PublicInteractions from "./interaction/PublicInteractions";
import MediaPage from "./media/MediaPage";
import Stats from "./stats/Stats";
import UserProfile from "./profile/UserProfile";
import OrganizationStats from "./stats/OrganizationStats";
import "./ui/common.scss";
import commonStore from "./util/commonStore";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/browser";
import { SENTRY_DSN } from "./config/Settings";
import Premium from "./auth/Premium";
import Library from "./auth/Library";
import { UserProvider } from "./auth/UserContext";
import { MediaProvider } from "./media/MediaContext";
import { RequestInteraction } from "./home/RequestInteraction";

import SubscriptionPage from "./premium/SubscriptionPage";

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			commonStore.getToken() ? (
				<Component {...props} />
			) : (
				<Redirect to="/login" />
			)
		}
	/>
);

const LoggedOutOnlyRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!commonStore.getToken() ? (
				<Component {...props} />
			) : (
				<Redirect to="/dashboard" />
			)
		}
	/>
);

const Main = () => (
	<Switch>
		<Route exact path="/" component={Home} showHeader={true} />
		<Route
			exact
			path="/new-homepage"
			component={HomeNew}
			showHeader={true}
		/>
		<Route exact path="/privacy-policy" component={Privacy} />
		<Route exact path="/premium" component={Premium} showHeader={true} />
		<Route exact path="/features" component={Features} />
		<Route exact path="/request" component={RequestInteraction} />
		<Route
			exact
			path="/subscribe"
			component={SubscriptionPage}
			showHeader={true}
		/>
		<Route exact path="/library" component={Library} showHeader={true} />
		<LoggedOutOnlyRoute exact path="/login" component={Login} />
		<LoggedOutOnlyRoute
			exact
			path="/forgot-password"
			component={ForgotPassword}
		/>
		<LoggedOutOnlyRoute
			exact
			path="/reset/:token/:uid"
			component={PasswordReset}
		/>
		<LoggedOutOnlyRoute exact path="/register" component={Register} />
		<Route path="/interactions/public" component={PublicInteractions} />
		<PrivateRoute
			path="/interactions/create"
			component={CreateInteraction}
		/>
		<Route
			path="/interactions/:hashid/preview"
			component={PreviewInteraction}
		/>
		<Route path="/stats" component={Stats} />

		<PrivateRoute path="/my-stats" component={OrganizationStats} />
		<PrivateRoute path="/interactions/:id" component={EditInteraction} />
		<PrivateRoute exact path="/dashboard" component={Dashboard} />
		<PrivateRoute path="/team" component={Team} />
		<PrivateRoute path="/media" component={MediaPage} />
		<PrivateRoute path="/profile" component={UserProfile} />
	</Switch>
);

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: SENTRY_DSN,
	});
}

class App extends Component {
	render() {
		return (
			<UserProvider>
				<MediaProvider>
					<Main />
					<ToastContainer hideProgressBar />
				</MediaProvider>
			</UserProvider>
		);
	}
}

export default App;
