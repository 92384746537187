import { ApiClient } from "../util/ApiClient.js";

class CategoryStore {
	findAll = async (filter = "") => {
		const response = await ApiClient().get("categories/" + filter);
		const data = await response.data;

		return data;
	};
}

export default CategoryStore;
