import React from "react";
import { Col, Image, Row, Badge } from "react-bootstrap";
import "./InteractionListItem.scss";

const InteractionListItem = ({ interaction, children }) => {
	return (
		<Row className="interaction-list-row">
			<Col md={1} className="vertical-align">
				<Image
					src={interaction.icon}
					alt={interaction.name}
					className="img-fluid interaction-list-image"
				/>
			</Col>
			<Col md={7}>
				<strong>
					{interaction.name}
					{interaction.is_premium ? (
						<Badge className="ml-3" variant="secondary">
							Premium
						</Badge>
					) : (
						""
					)}
				</strong>
				<p className="help-text">{interaction.description}</p>
			</Col>
			<Col md={4} className="vertical-align text-right">
				{children}
			</Col>
		</Row>
	);
};

export default InteractionListItem;
