import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import Header from "../ui/Header.js";
import Footer from "../ui/Footer.js";
import Loading from "../ui/Loading.js";
import "./login.scss";
import withSnapEngage from "../util/SnapEngage";
import { URL, RESET_PASSWORD_URL } from "../config/Api";
import AuthStore from "./AuthStore.js";

const PasswordReset = () => {
	const params = useParams();

	const [password, setPassword] = useState();
	const [email, setEmail] = useState();
	const [validToken, setValidToken] = useState();

	const history = useHistory();

	useEffect(() => {
		const authStore = new AuthStore();

		document.title = "iglooo - Login";

		axios
			.get(URL + RESET_PASSWORD_URL, {
				params: {
					token: params.token,
					uid: params.uid,
				},
			})
			.then((response) => {
				setValidToken(response.data.valid);
				setEmail(response.data.email);
			});

		return function cleanup() {
			authStore.reset();
		};
	}, [params.token, params.uid]);

	const handlePasswordChange = (e) => setPassword(e.target.value);

	const handleSubmit = (event) => {
		event.preventDefault();
		return axios
			.post(URL + RESET_PASSWORD_URL, {
				token: params.token,
				uid: params.uid,
				password: password,
			})
			.then((response) => {
				let success = response.data.success;

				if (success) {
					setEmail(email);
					setPassword(password);
					history.replace("/login");
				}
			});
	};

	let content;
	if (validToken === null) {
		content = (
			<>
				<h3>
					<Loading /> Checking token...
				</h3>
			</>
		);
	}

	if (validToken === false) {
		content = (
			<>
				<h3>Invalid URL</h3>
			</>
		);
	}

	if (validToken === true) {
		content = (
			<>
				<h1> Reset your password </h1>
				<form onSubmit={handleSubmit} method="POST" id="signup-form">
					<Form.Group controlId="username">
						<Form.Label>Password</Form.Label>
						<Form.Control
							required
							type="password"
							onChange={handlePasswordChange}
						/>
					</Form.Group>

					<div className="button">
						<button className="common-gradient">
							<span>Change Password</span>
						</button>
					</div>
				</form>
			</>
		);
	}

	return (
		<div className="page-login">
			<Header>
				<Row style={{ width: "100%" }}>
					<Col className="vertical-align igloo-login-nav">
						<Link to="/login" className="btn btn-rounded">
							Log in
						</Link>
						<Link to="/register" className="btn btn-rounded active">
							Sign up
						</Link>
					</Col>
				</Row>
			</Header>
			<div className="login-container container-fluid">
				<div className="frow row">
					<div className="col-sm">{content}</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default withSnapEngage(PasswordReset);
