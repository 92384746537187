import { alias, deserialize, identifier, serializable } from "serializr";

export default class Subscription {
	static fromJson(json) {
		return deserialize(this, json);
	}

	@serializable(identifier())
	id;

	@serializable
	name;

	@serializable
	description;

	@serializable
	duration;

	@serializable(alias("is_active"))
	isActive;

	@serializable(alias("interaction_limit"))
	interactionLimit;

	@serializable(alias("premium_interactions"))
	premiumInteractions;

	@serializable(alias("storage_limit"))
	storageLimit;

	@serializable(alias("user_limit"))
	userLimit;

	constructor(props) {
		if (!props) {
			props = {};
		}

		this.id = props.id;
		this.name = props.name;
		this.description = props.description;
		this.isActive = props.is_active;
		this.duration = props.duration;
		this.interactionLimit = props.interaction_limit;
		this.premiumInteractions = props.premium_interactions;
		this.storageLimit = props.storage_limit;
		this.userLimit = props.user_limit;
	}
}
